import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { SupplierTripMatrix } from "@/app/SupplierTrips/Components/SupplierTripMatrix";

export function SupplierTripMatrixDialog({
  supplierId,
}: {
  supplierId: string;
}) {
  return (
    <GenericDialog title="Trips">
      <SupplierTripMatrix supplierId={supplierId} />
    </GenericDialog>
  );
}
