import { Avatar } from "@/components/catalyst/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownItemAnchor,
  DropdownLabel,
  DropdownMenu,
} from "@/components/catalyst/dropdown";
import {
  Navbar,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from "@/components/catalyst/navbar";
import {
  Sidebar as BaseSidebar,
  SidebarBody,
  SidebarDivider,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItemAnchor,
  SidebarItemButton,
  SidebarItemLink,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from "@/components/catalyst/sidebar";
import { SidebarLayout } from "@/components/catalyst/sidebar-layout";
import {
  ArrowRightStartOnRectangleIcon,
  ChevronUpIcon,
  LightBulbIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/16/solid";
import {
  ArrowsRightLeftIcon,
  BuildingOffice2Icon,
  ClockIcon,
  Cog6ToothIcon,
  DocumentCurrencyDollarIcon,
  HomeIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  SwatchIcon,
  TicketIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { useClerk, useUser } from "@clerk/clerk-react";
import { SidebarHeaderDropdown } from "@/lib/Components/Layout/Sidebar/SidebarHeaderDropdown";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { recentBookings } from "@/lib/Components/Layout/Sidebar/recentBookings";
import {
  BookingStatus,
  InvoiceStatus,
  QueryBookingsOrderByColumn,
  QueryBookingsWhereColumn,
  RelocationStatus,
  SortOrder,
  SqlOperator,
} from "@/gql/graphql";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { now } from "@/lib/Utils/date";
import { toDateTimeTzString } from "@/lib/Formatters/toDateTimeString";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { ReactNode } from "react";
import { Badge } from "@/components/catalyst/badge";

export function Sidebar({ children }: { children: ReactNode }) {
  const { user } = useUser();
  const { supplierId, hasSupplier } = useGlobalSupplier();
  const { isAdmin } = useTenant();
  const { openUserProfile, signOut } = useClerk();
  const { data } = useGqlQuery(recentBookings, {
    status: [BookingStatus.Confirmed],
    page: 1,
    first: 5,
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QueryBookingsOrderByColumn.DepartAt,
      },
    ],
    supplierId: supplierId ?? undefined,
    where: {
      column: QueryBookingsWhereColumn.DepartAt,
      operator: SqlOperator.Gte,
      value: toDateTimeTzString(now().local().startOf("day")),
    },
  });

  return (
    <SidebarLayout
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src={user?.imageUrl} square />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem
                  onClick={() => {
                    openUserProfile();
                  }}
                >
                  <UserIcon />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItemAnchor href="https://www.imoova.com/en/privacy-policy">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItemAnchor>
                <DropdownItemAnchor href="mailto:tradepartners@imoova.com?subject=Website Feedback">
                  <LightBulbIcon />
                  <DropdownLabel>Share feedback</DropdownLabel>
                </DropdownItemAnchor>
                <DropdownDivider />
                <DropdownItem
                  onClick={() => {
                    signOut();
                  }}
                >
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <BaseSidebar>
          <SidebarHeader>
            <SidebarHeaderDropdown />
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              <SidebarItemLink to="/">
                <HomeIcon />
                <SidebarLabel>Home</SidebarLabel>
              </SidebarItemLink>
              <SidebarItemLink
                to="/bookings"
                search={{
                  bookingStatus: [],
                }}
              >
                <TicketIcon />
                <SidebarLabel>Bookings</SidebarLabel>
              </SidebarItemLink>
              <SidebarItemLink
                to="/relocations"
                search={{
                  relocationStatus: [RelocationStatus.Ready],
                }}
              >
                <ArrowsRightLeftIcon />
                <SidebarLabel>Relocations</SidebarLabel>
              </SidebarItemLink>

              <SidebarItemLink
                to={"/invoices"}
                search={{
                  invoiceStatus: [InvoiceStatus.Issued, InvoiceStatus.Overdue],
                }}
              >
                <DocumentCurrencyDollarIcon />
                <SidebarLabel>Invoices</SidebarLabel>
              </SidebarItemLink>

              {!hasSupplier ? (
                <>
                  <SidebarItemLink to={"/suppliers"}>
                    <BuildingOffice2Icon />
                    <SidebarLabel>Suppliers</SidebarLabel>
                  </SidebarItemLink>
                </>
              ) : null}

              <SidebarDivider />
              <SidebarItemLink to={"/settings"}>
                <Cog6ToothIcon />
                <SidebarLabel>Settings</SidebarLabel>
              </SidebarItemLink>

              {supplierId ? (
                <SidebarItemLink
                  to={"/import/$supplierId"}
                  params={{
                    supplierId: supplierId,
                  }}
                >
                  <PlusIcon />
                  <SidebarLabel>Import</SidebarLabel>
                </SidebarItemLink>
              ) : (
                <SidebarItemLink to="/import">
                  <PlusIcon />
                  <SidebarLabel>Import</SidebarLabel>
                </SidebarItemLink>
              )}

              {isAdmin ? (
                <>
                  <SidebarDivider />
                  <SidebarHeading>Admin section</SidebarHeading>
                  <SidebarItemLink to={"/waitlists"}>
                    <ClockIcon className="size-5" />
                    <SidebarLabel>Waitlists</SidebarLabel>
                  </SidebarItemLink>
                  <SidebarItemLink to={"/paid-drivers/drivers"}>
                    <UserCircleIcon className="size-5" />
                    <SidebarLabel>Paid Drivers</SidebarLabel>
                  </SidebarItemLink>

                  <SidebarDivider />
                  <SidebarHeading>New features</SidebarHeading>
                  <SidebarItemLink to={"/quotes"}>
                    <SwatchIcon />
                    <SidebarLabel>
                      Quotes{" "}
                      <Badge color="lime" className="ml-2">
                        Beta
                      </Badge>
                    </SidebarLabel>
                  </SidebarItemLink>
                </>
              ) : null}

              <SidebarDivider />
            </SidebarSection>
            {data?.bookings?.data?.length ? (
              <>
                <SidebarSection className="max-lg:hidden">
                  <SidebarHeading>Upcoming bookings</SidebarHeading>
                  {data?.bookings?.data?.map((booking) => (
                    <SidebarItemLink
                      className="truncate"
                      to="/bookings/$bookingId"
                      key={booking.id}
                      params={{
                        bookingId: booking.id,
                      }}
                    >
                      {booking.reference} - {booking.name}
                    </SidebarItemLink>
                  ))}
                </SidebarSection>
              </>
            ) : null}
            <SidebarSpacer />
            <SidebarSection>
              <SidebarItemAnchor
                href="https://articles.imoova.com/en/collections/8267011-trade-partners"
                target="_blank"
              >
                <QuestionMarkCircleIcon />
                <SidebarLabel>Support</SidebarLabel>
              </SidebarItemAnchor>
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItemButton}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar
                    src={user?.imageUrl}
                    className="size-10"
                    square
                    alt=""
                  />
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-white">
                      {user?.firstName}
                    </span>
                    <span className="block truncate text-xs/5 font-normal text-zinc-400">
                      {user?.primaryEmailAddress?.emailAddress ?? "--"}
                    </span>
                  </span>
                </span>
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem
                  onClick={() => {
                    openUserProfile();
                  }}
                >
                  <UserIcon />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItemAnchor href="https://www.imoova.com/en/privacy-policy">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItemAnchor>
                <DropdownItemAnchor href="mailto:tradepartners@imoova.com?subject=Website Feedback">
                  <LightBulbIcon />
                  <DropdownLabel>Share feedback</DropdownLabel>
                </DropdownItemAnchor>
                <DropdownDivider />
                <DropdownItem
                  onClick={() => {
                    signOut();
                  }}
                >
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </BaseSidebar>
      }
    >
      {children}
    </SidebarLayout>
  );
}
