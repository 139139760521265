import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { RelocationForm } from "@/app/Relocations/Forms/RelocationForm";
import {
  BookingStatus,
  QueryBookingsWhereColumn,
  RelocationInclusionType,
  SqlOperator,
  UpdateRelocationInput,
} from "@/gql/graphql";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import {
  RelocationRecord,
  relocationRecordQuery,
} from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { updateRelocationMutation } from "@/app/Relocations/GraphQL/relocationMutations";
import { getRelocationUpdateInput } from "@/app/Relocations/Utils/getRelocationUpdateInput";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { relocationValidation } from "@/app/Relocations/Validation/relocationValidation";
import { BookingTable } from "@/app/Dashboard/Components/BookingTable";
import { useConfirmDeleteDialog } from "@/lib/Components/Dialog/Hooks/useConfirmDeleteDialog";

export function RelocationEditDialog({ id }: { id: string }) {
  const { data } = useSuspenseGqlQuery(relocationRecordQuery, {
    id,
  });
  const { relocation } = data;

  const { mutateAsync } = useGqlMutation(updateRelocationMutation);
  const { close } = useDialogState();
  const { openAsPromise } = useConfirmDeleteDialog();

  return (
    <GenericDialog title="Edit relocation">
      <GenericForm<UpdateRelocationInput>
        validationSchema={toFormikValidationSchema(relocationValidation)}
        onSubmit={async (values, formikHelpers) => {
          if (
            relocation.bookedBookingsCount &&
            hasChanged(relocation, values)
          ) {
            try {
              await openAsPromise({
                title: "Are you sure you want to update this relocation?",
                onDelete: async () => {
                  await mutateAsync({
                    id: relocation.id,
                    input: values,
                  });
                },
                buttonText: "Update",
                message: `This relocation has ${relocation.bookedBookingsCount} bookings associated with it. Are you sure you want to update it?`,
                children: (
                  <div className="mt-3">
                    <BookingTable
                      id="dashboard-pending-bookings"
                      queryVariables={{
                        relocationId: relocation.id,
                        where: {
                          column: QueryBookingsWhereColumn.Status,
                          operator: SqlOperator.In,
                          value: [
                            BookingStatus.AwaitingConfirmation,
                            BookingStatus.Vip,
                            BookingStatus.Accepted,
                            BookingStatus.Confirmed,
                            BookingStatus.Completed,
                          ],
                        },
                      }}
                    />
                  </div>
                ),
              });
            } catch (e: any) {
              console.error(e);
              formikHelpers.resetForm();
              formikHelpers.setSubmitting(false);
            }
          } else {
            await mutateAsync({
              id: relocation.id,
              input: values,
            });
          }

          close();
        }}
        initialValues={getRelocationUpdateInput(relocation)}
      >
        <RelocationForm relocation={relocation} />
        <DialogSubmitButton text={"Save"} />
      </GenericForm>
    </GenericDialog>
  );
}

function hasChanged(
  relocation: RelocationRecord,
  newValues: UpdateRelocationInput,
) {
  //Check if the distance is no-longer unlimited
  if (
    relocation.distance_allowed === null &&
    newValues.distance_allowed !== null
  ) {
    return true;
  }

  //Check if the new distance is less than the old distance
  if (
    newValues.distance_allowed &&
    relocation.distance_allowed &&
    newValues.distance_allowed < relocation.distance_allowed
  ) {
    return true;
  }

  //Check if the number of relocation days is less
  if (
    newValues.hire_units_allowed &&
    newValues.hire_units_allowed < relocation.hire_units_allowed
  ) {
    return true;
  }

  //check if the fuel inclusion is less
  const oldFuelAmount = relocation.inclusions.reduce((acc, inclusion) => {
    if (inclusion.type === RelocationInclusionType.Fuel) {
      return acc + (inclusion.value ?? 0);
    }

    return acc;
  }, 0);

  const newFuelAmount =
    newValues.inclusions?.upsert?.reduce((acc, inclusion) => {
      if (inclusion.type === RelocationInclusionType.Fuel) {
        return acc + (inclusion.value ?? 0);
      }

      return acc;
    }, 0) ?? 0;

  if (newFuelAmount < oldFuelAmount) {
    return true;
  }

  //Check if the locations or vehicle have changed
  if (
    newValues.vehicle?.connect &&
    newValues.vehicle.connect !== relocation.vehicle.id
  ) {
    return true;
  }

  if (
    newValues.departureOffice?.connect &&
    newValues.departureOffice.connect !== relocation.vehicle.id
  ) {
    return true;
  }

  if (
    newValues.deliveryOffice?.connect &&
    newValues.deliveryOffice.connect !== relocation.vehicle.id
  ) {
    return true;
  }

  //Check if the pricing has changed
  if (
    newValues.hire_unit_rate &&
    newValues.hire_unit_rate !== relocation.hire_unit_rate
  ) {
    return true;
  }

  if (
    newValues.extra_hire_unit_rate &&
    newValues.extra_hire_unit_rate !== relocation.hire_unit_rate
  ) {
    return true;
  }

  if (newValues.currency && newValues.currency !== relocation.currency) {
    return true;
  }

  return false;
}
