import { RecordScreenFormCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenFormCard";
import { MediaCollection, ModelType, UpdateSupplierInput } from "@/gql/graphql";
import { FileUploader } from "@/lib/FileUpload/FileUploader";
import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { SupplierIcon } from "@/app/Suppliers/Components/SupplierIcon";
import { SupplierDetailsForm } from "@/app/Suppliers/Forms/SupplierDetailsForm";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierMutation } from "../GraphQL/supplierMutations";
import { createMediaMutation } from "@/app/Media/GraphQL/mediaMutations";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import { InfoBanner } from "@/lib/Components/Banners/InfoBanner";

export function SupplierDetailScreen() {
  const record = useSupplierRecord();
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);
  const { mutateAsync: createMedia } = useGqlMutation(createMediaMutation);

  return (
    <div className="space-y-12 py-6">
      <RecordScreenFormCard<UpdateSupplierInput>
        title="Admin notes"
        adminOnly={true}
        initialValues={{ contact_notes: record.contact_notes }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        view={
          <InfoBanner>
            <p
              dangerouslySetInnerHTML={{
                __html: record.contact_notes ?? "No notes added",
              }}
            />
          </InfoBanner>
        }
      >
        <RichTextInput
          name="contact_notes"
          label="Contact notes"
          className="col-span-full"
        />
      </RecordScreenFormCard>

      <RecordScreenFormCard<UpdateSupplierInput>
        initialValues={{
          name: record.name,
          email: record.email,
          phone: record.phone,
          currency: record.currency,
          measurement: record.measurement,
          hire_unit_type: record.hire_unit_type,
          minimum_age: record.minimum_age,
          maximum_age: record.maximum_age,
          minimum_no_surcharge_age: record.minimum_no_surcharge_age,
          maximum_no_surcharge_age: record.maximum_no_surcharge_age,
          age_surcharge_amount: record.age_surcharge_amount,
          holding_deposit_amount: record.holding_deposit_amount,
          holding_deposit_refunded_at: record.holding_deposit_refunded_at,
          debit_card_accepted: record.debit_card_accepted,
          standard_inclusions: record.standard_inclusions,
          extra_distance_allowed_per_extra_hire_unit:
            record.extra_distance_allowed_per_extra_hire_unit,
          excess_distance_rate: record.excess_distance_rate,
          default_extra_hire_units_allowed:
            record.default_extra_hire_units_allowed,
          has_age_surcharge: record.has_age_surcharge,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        title="Details"
      >
        <SupplierDetailsForm />
      </RecordScreenFormCard>

      <RecordScreenCard title="Logo">
        {record.logo ? (
          <SupplierIcon className="size-10" model={record} />
        ) : null}
        <FileUploader
          onUpload={(uploads) => {
            uploads.map((upload) => {
              return createMedia({
                input: {
                  file_name: upload.file.name,
                  objectId: record.id,
                  objectType: ModelType.Supplier,
                  collectionName: MediaCollection.Logo,
                  key: upload.temporary_url,
                },
              });
            });
          }}
        />
      </RecordScreenCard>
    </div>
  );
}
