import { bookingCardMessages } from "@/app/Bookings/Messages/bookingCardMessages";
import { vehicleMessages } from "@/app/Vehicles/messages/vehicleMessages";
import { relocationMessages } from "@/app/Relocations/messages/relocationMessages";
import { officeMessages } from "@/app/Offices/messages/officeMessages";
import { bookingMessages } from "@/app/Bookings/Messages/bookingMessages";
import { commonMessages } from "@/app/Common/messages/common";
import { cityMessages } from "@/app/Cities/Cities/messages/city";
import { taskMessages } from "@/app/Tasks/messages/task";
import { paymentMessages } from "@/app/Payments/messages/payment";
import { contactMessages } from "@/app/Contacts/messages/contact";
import { invoiceMessages } from "@/app/Invoices/messages/invoiceMessages";
import { supplierMessages } from "@/app/Suppliers/messages/supplierMessages";
import { quoteMessages } from "@/app/Quotes/messages/quoteMessages";
import { bookingChangeRequestMessages } from "@/app/Bookings/Messages/bookingChangeRequestMessages";
import { bookingPaymentRequestMessages } from "@/app/Bookings/Messages/bookingPaymentRequestMessages";
import { importMessages } from "@/app/Import/messages/importMessages";

export const messages = {
  booking: {
    ...bookingMessages,
    cards: bookingCardMessages,
  },
  bookingChangeRequest: bookingChangeRequestMessages,
  bookingPaymentRequest: bookingPaymentRequestMessages,
  vehicle: vehicleMessages,
  relocation: relocationMessages,
  office: officeMessages,
  common: commonMessages,
  contact: contactMessages,
  city: cityMessages,
  task: taskMessages,
  payment: paymentMessages,
  invoice: invoiceMessages,
  supplier: supplierMessages,
  quote: quoteMessages,
  import: importMessages,
};
