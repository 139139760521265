import { ResultOf } from "@graphql-typed-document-node/core";
import { bookingRecordQuery } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { CalendarDaysIcon, UserIcon } from "@heroicons/react/24/outline";
import { formatDate } from "@/lib/Formatters/formatDate";
import { Badge } from "@/components/catalyst/badge";
import { bookingStatusColorMap } from "@/app/Bookings/Utils/bookingStatusColorMap";
import { useTranslations } from "use-intl";
import { Dot } from "lucide-react";
import { ImoovaImage } from "@/app/Media/Components/Image";

type Booking = ResultOf<typeof bookingRecordQuery>["booking"];

export function BookingCard({ booking }: { booking: Booking }) {
  const primaryImage = booking.relocation.vehicle.images.at(0);
  const t = useTranslations("booking");

  return (
    <div className="flex gap-x-4">
      <div className="w-40">
        {primaryImage ? (
          <ImoovaImage
            media={primaryImage}
            alt={booking.relocation.vehicle.name}
          />
        ) : (
          <img src={booking.relocation.vehicle.heroImage} alt="default image" />
        )}
      </div>

      <div>
        <h2 className="flex items-center space-x-2 font-bold">
          <span>{booking.reference}</span>
          <Dot className="h-4 w-4 text-gray-400" />
          <span>
            {booking.relocation.departureOffice.name} -{" "}
            {booking.relocation.deliveryOffice.name}
          </span>
          <Dot className="h-4 w-4 text-gray-400" />
          <span>{booking.relocation.vehicle.name}</span>
        </h2>
        <p className="mt-1 space-x-2 text-xs">
          <Badge color={bookingStatusColorMap[booking.status]}>
            {t(`status.${booking.status}`)}
          </Badge>

          {booking.relocationLineReference?.reference ? (
            <Badge color="zinc">
              Line/Rego: {booking.relocationLineReference.reference}
            </Badge>
          ) : null}

          {booking.supplier_reference ? (
            <Badge color="zinc">
              {booking.relocation.supplier.name} reference:{" "}
              {booking.supplier_reference}
            </Badge>
          ) : null}
        </p>

        <div className="mt-2 space-y-1 text-xs">
          <p className="flex items-center space-x-2">
            <CalendarDaysIcon className="h-4 w-4 text-gray-400" />
            <span>
              {formatDate(booking.depart_at)} - {formatDate(booking.deliver_at)}
            </span>
          </p>
          <p className="flex items-center space-x-2">
            <UserIcon className="h-4 w-4 text-gray-400" />
            <span className="flex">
              {booking.name}
              <Dot className="h-4 w-4 text-gray-400" />
              {booking.email}
              <Dot className="h-4 w-4 text-gray-400" />
              {booking.phone}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
