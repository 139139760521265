import { useMemo } from "react";
import { FormikValues, useFormikContext } from "formik";
import { FormikProps } from "formik/dist/types";
import { FormationContextType } from "../Providers/Formation";
import { useFormStore } from "@/lib/Components/Form/Hooks/useFormStore";

export type FormationContext<TValue> = FormikProps<TValue> &
  FormationContextType & {
    hasUnsavedChanges: boolean;
    errorCount: number;
  };

export function useForm<TValue = FormikValues>(): FormationContext<TValue> {
  const context = useFormikContext<TValue>();
  const formationContext = useFormStore((state) => state);

  // if the form is editing and the initial values are different from the current values, then there are unsaved changes
  const hasUnsavedChanges =
    formationContext.isEditing &&
    JSON.stringify(context.values) !== JSON.stringify(context.initialValues);

  const flattenedErrors = useMemo(
    () => flattenObject(context.errors),
    [context.errors],
  );
  const errorCount = Object.keys(flattenedErrors).length;

  return {
    ...context,
    ...formationContext,
    hasUnsavedChanges,
    errorCount,
  };
}

const flattenObject = (obj: any, prefix = "") =>
  Object.keys(obj).reduce(
    (acc, k) => {
      const pre = prefix.length ? `${prefix}.` : "";
      if (typeof obj[k] === "object")
        Object.assign(acc, flattenObject(obj[k], pre + k));
      else {
        acc[pre + k] = obj[k];
      }
      return acc;
    },
    {} as Record<string, any>,
  );
