import { MediaConversionType, MediaFieldsFragment } from "@/gql/graphql";
import { ComponentProps, useEffect, useRef, useState } from "react";
import { cn } from "@/lib/utils";

export function ImoovaImage({
  media,
  src,
  conversion = MediaConversionType.Small,
  fill = false,
  priority = false,
  className,
  alt,
  ...props
}: {
  media?: MediaFieldsFragment;
  src?: string;
  alt: string;
  conversion?: MediaConversionType;
  fill?: boolean;
  priority?: boolean;
  className?: string;
} & Omit<ComponentProps<"img">, "src" | "srcSet" | "ref" | "loading" | "alt">) {
  const [isLoaded, setIsLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  // Validate that either media or src is provided
  if (!media && !src) {
    throw new Error("ImoovaImage requires either a media object or src");
  }

  // Determine if this is an SVG image
  const isSvg = media
    ? media.mime_type === "image/svg+xml" || media.url?.endsWith(".svg")
    : src
      ? src.endsWith(".svg") || src.startsWith("data:image/svg+xml")
      : false;

  // Find the requested conversion for media objects
  const mediaConversion =
    media && !isSvg
      ? media.conversions.find((c) => c.type === conversion)
      : null;

  // Handle image load
  useEffect(() => {
    const img = imageRef.current;
    if (img) {
      if (img.complete) {
        setIsLoaded(true);
      } else {
        const handleLoad = () => setIsLoaded(true);
        img.addEventListener("load", handleLoad);
        return () => img.removeEventListener("load", handleLoad);
      }
    }
  }, [mediaConversion?.url, src]);

  // Determine loading attribute
  const loadingAttr = priority ? "eager" : "lazy";

  // Construct the appropriate img props based on what we have
  const imgProps: Partial<ComponentProps<"img">> = {
    ref: imageRef,
    alt,
    loading: loadingAttr,
    className: cn(
      fill
        ? "absolute inset-0 h-full w-full object-cover"
        : "h-auto max-w-full",
      className,
    ),
    ...props,
    onLoad: () => {
      setIsLoaded(true);
      if (props.onLoad) {
        props.onLoad(new Event("load") as any);
      }
    },
  };

  // Handle SVG media
  if (media && isSvg && media.url) {
    return <img src={media.url} {...imgProps} />;
  }

  // Use media conversion
  if (media && mediaConversion) {
    return (
      <img
        src={mediaConversion.url}
        srcSet={mediaConversion.srcset}
        sizes={props.sizes || "100vw"}
        {...imgProps}
      />
    );
  }

  // Fallback to original media URL if conversion not found
  if (media && media.url) {
    return <img src={media.url} {...imgProps} />;
  }

  // Fallback to src string if media is not provided
  return <img src={src} {...imgProps} />;
}
