import { SupplierInput } from "@/app/Suppliers/Components/SupplierInput";
import { VehicleInput } from "@/app/Vehicles/Components/VehicleInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import {
  CreateRelocationInput,
  DepositRefundedAt,
  FeatureFlagType,
  HireUnitType,
  HoldingDepositRefundedBy,
  Measurement,
  RelocationFieldsFragment,
  RelocationStatus,
} from "@/gql/graphql";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { HireUnitTypeInput } from "@/app/Common/Components/HireUnitType";
import { DepositRefundedAtInput } from "@/app/Common/Components/DepositRefundedAtInput";
import { Suspense, useEffect, useState } from "react";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { CurrencyTypeInput } from "@/app/Common/Components/CurrencyTypeInput";
import { MeasurementInput } from "@/app/Common/Components/MeasurementInput";
import dayjs from "dayjs";
import { useTranslations } from "use-intl";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { floor } from "lodash";
import { HoldingDepositRefundedByInput } from "@/app/Common/Components/HoldingDepositRefundedByInput";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { SupplierInsuranceOptionInput } from "@/app/Suppliers/Components/SupplierInsuranceOptionInput";
import { useGqlQuery, useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { RelocationTripCard } from "@/app/Relocations/Forms/RelocationTripCard";
import { SupplierOfficeInput } from "@/app/Offices/Components/SupplierOfficeInput";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";
import { DistanceAllowedInput } from "@/lib/Components/Form/Inputs/DistanceAllowedInput";
import { supplierTripByOfficesQuery } from "@/app/SupplierTrips/GraphQL/supplierTripRecord";
import { useFeatureFlag } from "@/app/Meta/utils/useFeatureFlag";
import { RelocationInclusionForm } from "@/app/Relocations/Forms/RelocationInclusionForm";
import { supplierInsuranceOptionListQuery } from "@/app/Suppliers/GraphQL/supplierInsuranceOptionListQuery";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";

export function RelocationForm({
  initialShowAdvanced = false,
  relocation,
}: {
  relocation?: RelocationFieldsFragment;
  initialShowAdvanced?: boolean;
}) {
  const { values, initialValues } = useForm<CreateRelocationInput>();

  return (
    <>
      {!initialValues.supplier?.connect ? (
        <>
          <SupplierInput name="supplier.connect" label="Supplier" />
          <FormDivider />
        </>
      ) : null}

      {values.supplier.connect ? (
        <Suspense
          fallback={
            <div className="col-span-full flex items-center justify-center">
              <Spinner />
            </div>
          }
        >
          <FieldsWithSupplier
            initialShowAdvanced={initialShowAdvanced}
            relocation={relocation}
          />
        </Suspense>
      ) : null}
    </>
  );
}

function FieldsWithSupplier({
  initialShowAdvanced,
  relocation,
}: {
  initialShowAdvanced?: boolean;
  relocation?: RelocationFieldsFragment;
}) {
  const [showAdvanced, setShowAdvanced] = useState(initialShowAdvanced);
  const t = useTranslations("relocation");
  const { isAdmin } = useTenant();
  const { values, initialValues, setFieldValue, setValues, touched } =
    useForm<CreateRelocationInput>();

  if (!values.supplier.connect) {
    throw new Error("A supplier is required");
  }

  const { data: supplierData } = useSuspenseGqlQuery(supplierRecordQuery, {
    id: values.supplier.connect,
  });

  const { data: insuranceOptionsData } = useSuspenseGqlQuery(
    supplierInsuranceOptionListQuery,
    {
      supplierId: values.supplier.connect,
      page: 1,
      first: 20,
    },
  );

  const defaultInsuranceOption =
    insuranceOptionsData.supplierInsuranceOptions.data.find(
      (opt) => opt.is_default,
    );

  const supplier = supplierData.supplier;

  const variableRelocationRateEnabled = useFeatureFlag(
    FeatureFlagType.VariableRelocationRate,
  );

  useEffect(() => {
    if (supplier.id !== initialValues.supplier?.connect) {
      setValues((old) => ({
        ...old,
        booking_fee_amount: supplier.booking_fee_amount,
        charge_per_move_amount: supplier.charge_per_move_amount,
        holding_deposit_amount: supplier.is_holding_deposit_required
          ? supplier!.holding_deposit_amount
          : 0,
        holding_deposit_refunded_by: supplier.holding_deposit_refunded_by,
        holding_deposit_refunded_at: supplier.holding_deposit_refunded_at,
        hire_unit_type: supplier.hire_unit_type,
        currency: supplier.currency,
        measurement: supplier.measurement,
        extra_distance_allowed_per_extra_hire_unit:
          supplier.extra_distance_allowed_per_extra_hire_unit,
        excess_distance_rate: supplier.excess_distance_rate,
        line: {
          upsert: {
            reference: "",
            supplier_id: supplier.id,
          },
          delete: [],
        },
      }));
    }
  }, [supplier]);

  useEffect(() => {
    if (
      !initialValues.supplierInsuranceOption?.connect &&
      defaultInsuranceOption
    ) {
      setFieldValue("supplierInsuranceOption", {
        connect: defaultInsuranceOption.id,
      });
    }
  }, [defaultInsuranceOption]);

  const { data: supplierTripData } = useGqlQuery(
    supplierTripByOfficesQuery,
    {
      officeAId: values.departureOffice?.connect!,
      officeBId: values.deliveryOffice?.connect!,
    },
    {
      enabled:
        !!values.departureOffice?.connect && !!values.deliveryOffice?.connect,
    },
  );

  useEffect(() => {
    if (!supplierTripData?.supplierTripByOffices) return;

    const trip = supplierTripData?.supplierTripByOffices;

    //See if the values are set already, don't apply the new trip values
    if (relocation) return;

    setValues((old) => ({
      ...old,
      hire_units_allowed: trip.hire_units_allowed,
      extra_hire_units_allowed: trip.extra_hire_units_allowed,
      minimum_hire_units: trip.minimum_hire_units,
      distance_allowed: trip.distance_allowed,
      is_ferry_required: trip.is_ferry_required,
    }));
  }, [supplierTripData?.supplierTripByOffices?.id]);

  const currency = values.currency;

  const distanceLabel =
    values?.measurement === Measurement.Imperial ? "ml" : "km";

  const unitLabel = t(
    `hire_unit_type.${values?.hire_unit_type ?? HireUnitType.Day}`,
  ) as string;

  const minimumHireUnits = values.minimum_hire_units ?? 0;
  const minimumCalendarDays =
    (values.hire_unit_type ?? HireUnitType.Day === HireUnitType.Day)
      ? minimumHireUnits - 1
      : minimumHireUnits;

  return (
    <>
      <SupplierOfficeInput
        name="departureOffice.connect"
        label="From"
        className="col-span-3"
        getQueryVariables={(search) => ({
          first: 20,
          page: 1,
          search,
          supplier_id: values.supplier?.connect,
          archived: false,
        })}
      />

      <SupplierOfficeInput
        name="deliveryOffice.connect"
        label="To"
        className="col-span-3"
        getQueryVariables={(search) => ({
          first: 20,
          page: 1,
          search,
          supplier_id: values.supplier?.connect,
          archived: false,
        })}
      />

      <VehicleInput
        name="vehicle.connect"
        label="Vehicle"
        className="col-span-3"
        getQueryVariables={(search) => ({
          first: 20,
          page: 1,
          search,
          supplier_id: values.supplier?.connect,
        })}
        initialFixtureValues={{
          supplier: {
            connect: values.supplier?.connect ?? null,
          },
        }}
        onChange={async (vehicle) => {
          if (!vehicle) {
            return;
          }

          await setFieldValue("hire_unit_type", vehicle.hire_unit_type);
          await setFieldValue("hire_unit_rate", vehicle.hire_unit_rate);
          await setFieldValue(
            "extra_hire_unit_rate",
            vehicle.extra_hire_unit_rate,
          );
          await setFieldValue(
            "extra_hire_unit_deposit_rate",
            vehicle.extra_hire_unit_deposit_rate,
          );
          await setFieldValue(
            "extra_hire_unit_supplier_net_rate",
            vehicle.extra_hire_unit_supplier_net_rate,
          );
        }}
      />

      <SupplierInsuranceOptionInput
        name="supplierInsuranceOption.connect"
        label="Insurance"
        className="col-span-3"
        getQueryVariables={(search) => ({
          search,
          page: 1,
          first: 20,
          supplierId: values.supplier?.connect,
        })}
        initialFixtureValues={{
          supplier: values.supplier,
        }}
      />

      <div className="col-span-full" />

      {values.vehicle?.connect &&
      values.deliveryOffice?.connect &&
      values.departureOffice?.connect ? (
        <>
          <div className="col-span-full mb-6 p-6">
            <RelocationTripCard />
          </div>

          <NumberInput
            name="hire_units_allowed"
            inputProps={{
              min: 0,
            }}
            label={`${unitLabel}s allowed`}
            onChange={(value) => {
              if (!touched.minimum_hire_units && value) {
                setFieldValue("minimum_hire_units", floor(value / 2) + 1);
              }
            }}
          />

          <NumberInput
            name="extra_hire_units_allowed"
            inputProps={{
              min: 0,
            }}
            label={`Extra ${unitLabel}s allowed`}
          />

          <NumberInput
            name="minimum_hire_units"
            inputProps={{
              min: 0,
            }}
            label={`Minimum ${unitLabel.toLowerCase()}s`}
            tooltip="The minimum number of hire units that can be booked"
          />

          <DistanceAllowedInput
            name="distance_allowed"
            tooltip="Mileage allowed for the trip (empty for unlimited)"
            label={`${distanceLabel}s allowed`}
            measurement={values.measurement}
          />
          <FormDivider />

          <DateInput
            name={"available_from_date"}
            label={"Available from"}
            min={dayjs().local().format("YYYY-MM-DD")}
            max={dayjs().local().add(1, "year").format("YYYY-MM-DD")}
            onChange={(value) => {
              if (value === null) return;

              if (dayjs(value).isAfter(values.available_to_date)) {
                setFieldValue("available_to_date", null);
              }
            }}
          />
          <DateInput
            name={"available_to_date"}
            label={"Available to"}
            min={dayjs(values.available_from_date)
              .add(minimumCalendarDays, "days")
              .local()
              .format("YYYY-MM-DD")}
            max={dayjs().local().add(1, "year").format("YYYY-MM-DD")}
          />
          <NumberInput
            inputProps={{
              min: relocation?.status === RelocationStatus.Ready ? 1 : 0,
            }}
            name={"count"}
            label={"Quantity"}
            tooltip="How many vehicles did you want to relocate?"
          />
          <TextInput
            name="line.upsert.reference"
            label="Rego / line"
            tooltip="Comma separated (if multiple)"
            optionalLabel
          />

          <FormDivider />

          <RelocationInclusionForm relocation={relocation} />

          <div className="col-span-full flex justify-center py-6">
            <TextButton
              Icon={!showAdvanced ? ChevronDownIcon : ChevronUpIcon}
              onClick={() => {
                setShowAdvanced(!showAdvanced);
              }}
            >
              Additional settings
            </TextButton>
          </div>

          {showAdvanced ? (
            <>
              <CurrencyTypeInput name="currency" label="Currency" />
              <MeasurementInput name={"measurement"} label={"Measurement"} />
              <HireUnitTypeInput name="hire_unit_type" label="Hire unit" />

              <FormDivider />
              <CurrencyInput
                name="hire_unit_rate"
                label="Relocation rate"
                tooltip="This rate is set by Imoova for your region"
                currency={currency}
                isEditable={isAdmin || variableRelocationRateEnabled}
              />

              <CurrencyInput
                currency={currency}
                isEditable={false}
                name="extra_hire_unit_rate"
                label={`Extra ${unitLabel} rate`}
              />
              <CurrencyInput
                currency={currency}
                onChange={async (value) => {
                  if (value === null) return;

                  await setFieldValue(
                    "extra_hire_unit_rate",
                    value + values.extra_hire_unit_deposit_rate,
                  );
                }}
                name="extra_hire_unit_supplier_net_rate"
                label="Supplier net"
              />
              <CurrencyInput
                currency={currency}
                isEditable={isAdmin}
                name="extra_hire_unit_deposit_rate"
                onChange={(value) => {
                  setFieldValue(
                    "extra_hire_unit_rate",
                    (value ?? 0) +
                      (values.extra_hire_unit_supplier_net_rate ?? 0),
                  );
                }}
                label="Imoova net"
              />
              <div className="col-span-3" />
              <FormDivider />
              <DistanceAllowedInput
                label={`Extra ${distanceLabel} per ${unitLabel}`}
                name="extra_distance_allowed_per_extra_hire_unit"
                measurement={values.measurement}
              />
              <CurrencyInput
                currency={currency}
                optionalLabel
                name="excess_distance_rate"
                label={`Excess ${distanceLabel} rate`}
              />

              {isAdmin ? (
                <>
                  <FormDivider />
                  <CurrencyInput
                    currency={currency}
                    name={"booking_fee_amount"}
                    label={"Booking fee"}
                  />
                  <CurrencyInput
                    label="Charge per move"
                    name="charge_per_move_amount"
                    currency={currency}
                  />

                  <FormDivider />

                  <CurrencyInput
                    currency={currency}
                    name={"holding_deposit_amount"}
                    label={"Holding deposit"}
                  />

                  {values.holding_deposit_amount > 0 ? (
                    <>
                      <HoldingDepositRefundedByInput
                        name="holding_deposit_refunded_by"
                        label="Holding deposit refunded by"
                        onChange={(value) => {
                          if (value === HoldingDepositRefundedBy.Imoova) {
                            setFieldValue(
                              "holding_deposit_refunded_at",
                              DepositRefundedAt.Delivery,
                            );
                          }
                        }}
                      />
                      <DepositRefundedAtInput
                        isEditable={
                          values.holding_deposit_refunded_by !==
                          HoldingDepositRefundedBy.Imoova
                        }
                        name={"holding_deposit_refunded_at"}
                        label="Holding deposit refunded at"
                      />
                    </>
                  ) : null}
                </>
              ) : null}

              <FormDivider />
              <DateInput
                name={"fixed_latest_departure_date"}
                label={"Latest departure date"}
                optionalLabel
                min={values.available_from_date}
                max={values.available_to_date}
              />
              <SwitchInput
                name="is_ferry_required"
                label="Require ferry date"
                tooltip="Require the customer to provide a ferry date"
              />
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
}
