import { graphql } from "@/gql";

export const createPaidDriverRate = graphql(`
  mutation CreatePaidDriver($input: CreatePaidDriverRateInput!) {
    createPaidDriverRate(input: $input) {
      id
    }
  }
`);

export const updatePaidDriverRate = graphql(`
  mutation UpdatePaidDriverRate($id: ID!, $input: UpdatePaidDriverRateInput!) {
    updatePaidDriverRate(id: $id, input: $input) {
      id
    }
  }
`);

export const deletePaidDriverRate = graphql(`
  mutation DeletePaidDriverRate($id: ID!) {
    deletePaidDriverRate(id: $id) {
      id
    }
  }
`);
