import { HireUnitType, Measurement } from "@/gql/graphql";

export const importMessages = {
  mileage_label: {
    [Measurement.Imperial]: "Mileage",
    [Measurement.Metric]: "Kms allowance",
  },
  relocation_days_label: {
    [HireUnitType.Day]: "Relocation days",
    [HireUnitType.Night]: "Relocation nights",
    [HireUnitType.TwentyFourHours]: "Relocation 24hrs",
  } satisfies {
    [key in HireUnitType]: string;
  },
  extra_days_label: {
    [HireUnitType.Day]: "Extra days",
    [HireUnitType.Night]: "Extra nights",
    [HireUnitType.TwentyFourHours]: "Extra 24hrs",
  } satisfies {
    [key in HireUnitType]: string;
  },
};
