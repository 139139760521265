import { graphql } from "@/gql";

export const createQuoteMutation = graphql(`
  mutation CreateQuote($input: CreateQuoteInput!) {
    createQuote(input: $input) {
      id
    }
  }
`);

export const updateQuoteMutation = graphql(`
  mutation UpdateQuote($id: ID!, $input: UpdateQuoteInput!) {
    updateQuote(id: $id, input: $input) {
      id
    }
  }
`);

export const createQuotePdfMutation = graphql(`
  mutation CreateQuotePdf($id: ID!) {
    createQuotePdf(id: $id)
  }
`);

export const issueQuoteMutation = graphql(`
  mutation IssueQuote($id: ID!) {
    issueQuote(id: $id) {
      id
    }
  }
`);

export const acceptQuoteMutation = graphql(`
  mutation AcceptQuote($id: ID!) {
    acceptQuote(id: $id) {
      id
    }
  }
`);

export const declineQuoteMutation = graphql(`
  mutation DeclineQuote($id: ID!) {
    declineQuote(id: $id) {
      id
    }
  }
`);

export const invoiceQuoteMutation = graphql(`
  mutation InvoiceQuote($id: ID!) {
    invoiceQuote(id: $id) {
      id
    }
  }
`);
