import { ReactNode } from "react";
import { useDialogState } from "./Hooks/useDialogState";
import { DialogProvider } from "@/lib/Components/Dialog/Providers/DialogProvider";
import {
  Dialog,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "@/components/catalyst/dialog";

export interface GenericDialogProps {
  children: ReactNode;
  isLoading?: boolean;
  title: ReactNode;
  subtitle?: ReactNode;
  onClose?: () => void;
  className?: string;
}

export function GenericDialog({
  children,
  title,
  subtitle,
  onClose,
  className,
}: GenericDialogProps) {
  const { reject, close, isOpen, depth, remove } = useDialogState();

  return (
    <Dialog
      size="4xl"
      open={isOpen}
      onClose={() => {
        onClose?.();
        close();
      }}
      as="div"
      className={className}
    >
      <DialogProvider depth={depth + 1}>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>{subtitle}</DialogDescription>
        <DialogBody>{children}</DialogBody>
      </DialogProvider>
    </Dialog>
  );
}
