import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import {
  CreateSupplierTripInput,
  Currency,
  HireUnitType,
  Measurement,
} from "@/gql/graphql";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { GenericFieldArray } from "@/lib/Components/Form/FieldArray/GenericFieldArray";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { useTranslations } from "use-intl";
import { floor } from "lodash";
import { LightbulbIcon } from "lucide-react";
import { RelocationInclusionTypeInput } from "@/app/Relocations/Components/RelocationInclusionTypeInput";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { SupplierOfficeInput } from "@/app/Offices/Components/SupplierOfficeInput";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierOfficeRecordQuery } from "@/app/Offices/GraphQL/supplierOfficeRecordQuery";
import { DistanceAllowedInput } from "@/lib/Components/Form/Inputs/DistanceAllowedInput";
import { tripByCities } from "@/app/Cities/Trips/GraphQL/tripByCities";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import { Suspense } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

export function SupplierTripForm({ supplier }: { supplier: SupplierRecord }) {
  const { values, touched, initialValues, setFieldValue } =
    useForm<CreateSupplierTripInput>();

  const t = useTranslations("relocation");

  const hireUnitLabel = t(`hire_unit_type.${supplier.hire_unit_type}`);

  return (
    <>
      {!initialValues.officeA?.connect && !initialValues.officeB?.connect ? (
        <>
          <SupplierOfficeInput
            name="officeA.connect"
            label="Office A"
            getQueryVariables={(search) => ({
              first: 20,
              page: 1,
              search,
              archived: false,
              supplier_id: supplier.id,
            })}
          />
          <SupplierOfficeInput
            name="officeB.connect"
            label="Office B"
            getQueryVariables={(search) => ({
              first: 20,
              page: 1,
              search,
              archived: false,
              supplier_id: supplier.id,
            })}
          />
        </>
      ) : null}

      {values.officeA.connect && values.officeB.connect && supplier ? (
        <>
          <div className="col-span-full mb-6 px-6">
            <Suspense fallback={<Skeleton className="h-[280px] w-full" />}>
              <TripRecommendations
                officeAId={values.officeA.connect}
                officeBId={values.officeB.connect}
                measurement={supplier.measurement}
                hireUnitType={supplier.hire_unit_type}
              />
            </Suspense>
          </div>
        </>
      ) : null}

      <NumberInput
        name="hire_units_allowed"
        label={`${hireUnitLabel}s allowed`}
        inputProps={{
          min: 0,
        }}
        onChange={(value) => {
          if (value === null) return;

          if (!touched.minimum_hire_units) {
            setFieldValue("minimum_hire_units", floor(value / 2) + 1);
          }
        }}
      />

      <NumberInput
        name="extra_hire_units_allowed"
        label={`Extra ${hireUnitLabel}s allowed`}
      />

      <NumberInput
        name="minimum_hire_units"
        label={`Minimum ${hireUnitLabel}s allowed`}
      />
      <FormDivider />
      <DistanceAllowedInput
        name="distance_allowed"
        label={`${
          supplier?.measurement === Measurement.Metric ? "Kms" : "Mls"
        } allowed`}
        measurement={supplier?.measurement ?? Measurement.Metric}
      />

      <SwitchInput
        name="is_ferry_required"
        label="Is ferry required"
        tooltip="Do you want the customer to specify which ferry date they would prefer"
      />

      <GenericFieldArray
        label="Standard inclusions"
        fieldArrayKey="inclusions.upsert"
        newLineItemValues={{
          type: null,
          description: null,
          value: null,
        }}
      >
        {(item, index) => {
          return (
            <>
              <RelocationInclusionTypeInput
                name={`inclusions.upsert.${index}.type`}
                label="Type"
              />
              <CurrencyInput
                currency={supplier?.currency ?? Currency.Usd}
                name={`inclusions.upsert.${index}.value`}
                label="Monetary value"
                optionalLabel
              />
              <TextAreaInput
                name={`inclusions.upsert.${index}.description`}
                label="Description"
                className="col-span-full"
              />
            </>
          );
        }}
      </GenericFieldArray>
    </>
  );
}

export function TripRecommendations({
  officeAId,
  officeBId,
  measurement,
  hireUnitType,
}: {
  officeAId: string;
  officeBId: string;
  measurement: Measurement;
  hireUnitType: HireUnitType;
}) {
  const t = useTranslations("relocation");

  const { data: departureData } = useSuspenseGqlQuery(
    supplierOfficeRecordQuery,
    {
      id: officeAId,
    },
  );

  const { data: deliveryData } = useSuspenseGqlQuery(
    supplierOfficeRecordQuery,
    {
      id: officeBId,
    },
  );

  const { data } = useSuspenseGqlQuery(tripByCities, {
    cityAId: departureData?.supplierOffice.city.id!,
    cityBId: deliveryData?.supplierOffice.city.id!,
  });

  const { distance, duration } = data.tripByCities;

  const hireUnitLabel = t(`hire_unit_type.${hireUnitType}`) as string;

  const measurementLabel = measurement === Measurement.Metric ? "kms" : "mls";

  const hireUnits = duration - (hireUnitType === HireUnitType.Day ? 0 : 1);

  //Convert distance to miles if measurement is imperial
  const distanceUnits =
    measurement === Measurement.Metric
      ? distance
      : (distance * 0.621371).toFixed(0);

  const origin =
    data.tripByCities.cityA.id === departureData!.supplierOffice.city.id
      ? data.tripByCities.cityA
      : data.tripByCities.cityB;

  const destination =
    data.tripByCities.cityA.id === departureData!.supplierOffice.city.id
      ? data.tripByCities.cityB
      : data.tripByCities.cityA;

  return (
    <div className="w-full overflow-hidden rounded-md shadow-md">
      <img
        src={data.tripByCities.landscape_image.url}
        alt="x to y"
        className="h-[200px] w-full"
      />

      <div className="flex bg-yellow-50 p-4">
        <div className="flex-shrink-0">
          <LightbulbIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Hint</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              For this trip, we recommend{" "}
              <strong>
                {Math.max(hireUnits, 1)} x {hireUnitLabel.toLowerCase()}s
              </strong>{" "}
              and{" "}
              <strong>
                {Math.max(distanceUnits as number, 100)} {measurementLabel}
              </strong>{" "}
              for {origin.name} {destination.name}.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
