import { InvoiceTable } from "@/app/Invoices/Components/InvoiceTable";
import { useQuoteRecord } from "@/app/Quotes/hooks/useQuoteRecord";
import { QueryInvoicesWhereColumn, SqlOperator } from "@/gql/graphql";

export function QuoteInvoicesScreen() {
  const quote = useQuoteRecord();
  return (
    <div>
      <InvoiceTable
        id={"invoices"}
        queryVariables={{
          where: {
            column: QueryInvoicesWhereColumn.QuoteId,
            operator: SqlOperator.Eq,
            value: quote.id,
          },
        }}
      />
    </div>
  );
}
