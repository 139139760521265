import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type Contact = ResultOf<typeof contactRecordQuery>["contact"];
export type ContactRecord = ResultOf<typeof contactRecordQuery>["contact"];
export const contactRecordQuery = graphql(`
  query ContactRecord($id: ID!) {
    contact(id: $id) {
      id
      type
      name
      legal_name
      purchase_order_number
      company_number
      billing_cycle_day_of_month
      emails {
        id
        email
        is_primary
        is_billing
        is_invoice_reminder
      }
      phones {
        id
        phone
        is_billing
        is_primary
      }
      billingEmail {
        id
        email
      }
      billingAddress {
        ...AddressFields
      }
      profilePicture {
        ...MediaFields
      }
      created_at
      updated_at
    }
  }
`);

export const contactRecordFragment = graphql(`
  fragment ContactRecordFields on Contact {
    id
    name
    legal_name
    company_number
    type
    purchase_order_number
    billing_cycle_day_of_month
    profilePicture {
      ...MediaFields
    }
    emails {
      id
      email
      is_billing
      is_primary
      is_invoice_reminder
    }
    phones {
      id
      phone
      is_billing
      is_primary
    }
    billingAddress {
      ...AddressFields
    }
    billingEmail {
      id
      email
    }
    billingPhone {
      id
      phone
    }
    profilePicture {
      ...MediaFields
    }
    created_at
    updated_at
  }
`);
