import { Button } from "@/lib/Components/Button/Button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { CreateQuoteDialog } from "@/app/Quotes/Components/CreateQuoteDialog";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useMatch, useNavigate } from "@tanstack/react-router";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  Navbar,
  NavbarItemLink,
  NavbarSection,
} from "@/components/catalyst/navbar";
import { Badge, BadgeColor } from "@/components/catalyst/badge";
import { quoteStatusCountQuery } from "@/app/Quotes/GraphQL/quoteStatusCountQuery";
import { QuoteStatus } from "@/gql/graphql";
import { QuoteTable } from "@/app/Quotes/Components/QuoteTable";
import { Suspense } from "react";

export function QuoteTableScreen() {
  const { open } = useDialog(CreateQuoteDialog);
  const { search } = useMatch({
    from: "/_app/quotes/",
  });

  const navigate = useNavigate();

  return (
    <FullPageScreen>
      <Header />
      <QuoteTable
        id="quotes"
        statuses={search?.quoteStatus ?? []}
        buttons={
          <Button
            LeadingIcon={PlusIcon}
            onClick={() => {
              open({});
            }}
          >
            Add quote
          </Button>
        }
        onStatusChange={(newStatuses) => {
          navigate({
            to: "/quotes",
            search: {
              quoteStatus: newStatuses,
            },
          });
        }}
      />
    </FullPageScreen>
  );
}

type QuoteFilter = {
  label: string;
  value: QuoteStatus[] | undefined;
  badgeColor?: BadgeColor;
};

const quoteFilters: QuoteFilter[] = [
  {
    label: "All",
    value: [],
    badgeColor: "zinc",
  },
  {
    label: "Accepted",
    value: [QuoteStatus.Accepted],
    badgeColor: "green",
  },
  {
    label: "Issued",
    value: [QuoteStatus.Issued],
    badgeColor: "yellow",
  },
  {
    label: "Draft",
    value: [QuoteStatus.Draft],
  },
  {
    label: "Invalid",
    value: [QuoteStatus.Expired, QuoteStatus.Rejected],
  },

  {
    label: "Invoiced",
    value: [QuoteStatus.Invoiced],
  },
];

function Header() {
  const { data } = useSuspenseGqlQuery(quoteStatusCountQuery, {});
  const navigate = useNavigate({
    from: "/quotes/",
  });

  return (
    <Suspense>
      <div className="relative border-b border-gray-200 sm:pb-0">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Quotes
        </h3>
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            onChange={(e) => {
              const statuses = quoteFilters.find(
                (tab) => tab.label === e.target.value,
              );

              navigate({
                to: "/quotes",
                search: {
                  quoteStatus: statuses?.value ?? [],
                },
              });
            }}
          >
            {quoteFilters.map((tab, idx) => (
              <option key={idx} value={tab.label}>
                {tab.label}
              </option>
            ))}
          </select>
        </div>
        <Navbar className="-mb-px">
          <NavbarSection className="max-lg:hidden">
            {quoteFilters.map(({ label, value, badgeColor }, idx) => {
              const countItem = data.quoteStatusCount.find((quoteCountItem) => {
                return value?.includes(quoteCountItem.status);
              });
              return (
                <NavbarItemLink
                  key={idx}
                  to="/quotes"
                  search={{
                    quoteStatus: value,
                  }}
                >
                  {label}

                  {countItem && badgeColor ? (
                    <div>
                      <Badge className="py-0" color={badgeColor}>
                        {countItem.count}
                      </Badge>
                    </div>
                  ) : null}
                </NavbarItemLink>
              );
            })}
          </NavbarSection>
        </Navbar>
      </div>
    </Suspense>
  );
}
