import { SVGProps } from "react";
import { JSX } from "react/jsx-runtime";

export const GridIconFull = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="lucide lucide-grip"
    {...props}
  >
    <circle cx={4} cy={4} r={1} />
    <circle cx={11} cy={4} r={1} />
    <circle cx={18} cy={4} r={1} />
    <circle cx={4} cy={11} r={1} />
    <circle cx={11} cy={11} r={1} />
    <circle cx={18} cy={11} r={1} />
    <circle cx={4} cy={18} r={1} />
    <circle cx={11} cy={18} r={1} />
    <circle cx={18} cy={18} r={1} />
  </svg>
);
