import { ReactNode, Suspense } from "react";
import { cn } from "@/lib/utils";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";

interface RecordScreenCardProps {
  children: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  buttons?: ReactNode;
  className?: string;
}

export function RecordScreenCard({
  children,
  subtitle,
  title,
  buttons,
  className,
}: RecordScreenCardProps) {
  return (
    <div className={cn("", className)}>
      <header className="flex pb-4">
        <div className="flex-grow">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </h3>
          {subtitle && (
            <p className="mt-1 max-w-2xl text-sm text-gray-500">{subtitle}</p>
          )}
        </div>
        <div>{buttons}</div>
      </header>
      <Suspense
        fallback={
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        }
      >
        {children}
      </Suspense>
    </div>
  );
}
