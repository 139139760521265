import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { SupplierTripForm } from "@/app/SupplierTrips/Forms/SupplierTripForm";
import { CreateSupplierTripInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { createSupplierTripValidator } from "@/app/SupplierTrips/Validators/createSupplierTripValidator";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { createSupplierTripMutation } from "@/app/SupplierTrips/GraphQL/supplierTripMutations";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

type SupplierTripCreateDialogProps = {
  initialValues?: Partial<Omit<CreateSupplierTripInput, "supplier">>;
  supplierId: string;
};
export function SupplierTripCreateDialog({
  initialValues,
  supplierId,
}: SupplierTripCreateDialogProps) {
  const { mutateAsync } = useGqlMutation(createSupplierTripMutation);
  const { close } = useDialogState();
  const { data } = useSuspenseGqlQuery(supplierRecordQuery, {
    id: supplierId,
  });
  const { supplier } = data;

  return (
    <GenericDialog title="Add trip">
      <GenericForm<CreateSupplierTripInput>
        validationSchema={toFormikValidationSchema(createSupplierTripValidator)}
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });

          close();
        }}
        initialValues={{
          officeA: { connect: null! },
          officeB: { connect: null! },
          supplier: {
            connect: supplierId,
          },
          distance_allowed: null!,
          hire_units_allowed: null!,
          minimum_hire_units: 1,
          extra_hire_units_allowed:
            supplier.default_extra_hire_units_allowed ?? 0,
          is_ferry_required: false,
          inclusions: {
            upsert: [],
            delete: [],
          },
          ...initialValues,
        }}
      >
        <SupplierTripForm supplier={supplier} />
        <DialogSubmitButton text="Add trip" />
      </GenericForm>
    </GenericDialog>
  );
}
