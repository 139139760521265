import { RecordScreen } from "@/lib/Components/Screens/RecordScreen/RecordScreen";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { ActivityIcon, DollarSignIcon } from "lucide-react";
import { Badge } from "@/components/catalyst/badge";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useQuoteRecord } from "@/app/Quotes/hooks/useQuoteRecord";
import { useQuoteActions } from "@/app/Quotes/hooks/useQuoteActions";
import { useTranslations } from "use-intl";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { ContactEditDialog } from "@/app/Contacts/Components/ContactEditDialog";
import { Button } from "@/components/catalyst/button";

export function QuoteRecordScreen() {
  const quote = useQuoteRecord();
  const getActions = useQuoteActions();
  const t = useTranslations("quote");

  return (
    <RecordScreen
      title={quote.reference}
      actionsGroups={getActions(quote)}
      badges={<Badge>{t(`status.${quote.status}`)}</Badge>}
      breadcrumbs={
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink to="/quotes">Quotes</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{quote.reference}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      }
      banner={<BillingEmailMissingBanner />}
      tabs={[
        {
          name: "Details",
          to: "/quotes/$quoteId",
          Icon: DocumentTextIcon,
        },
        {
          name: "Activity",
          to: "/quotes/$quoteId/activity",
          Icon: ActivityIcon,
        },
        {
          name: "Invoices",
          to: "/quotes/$quoteId/invoices",
          Icon: DollarSignIcon,
        },
      ]}
    />
  );
}

function BillingEmailMissingBanner() {
  const record = useQuoteRecord();
  const { open } = useDialog(ContactEditDialog);

  if (record.payee.billingEmail) {
    return null;
  }

  return (
    <WarningBanner className="my-6 py-1">
      <div>
        A billing email is missing for {record.payee.name}.{" "}
        <Button
          plain
          className="underline"
          onClick={() => {
            open({
              contactId: record.payee.id,
            });
          }}
        >
          fix now
        </Button>
      </div>
    </WarningBanner>
  );
}
