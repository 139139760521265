import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updatePaidDriverRate } from "@/app/PaidDrivers/PaidDrivers/GraphQL/paidDriverRateMutation";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { PaidDriverRateListItem } from "@/app/PaidDrivers/PaidDrivers/GraphQL/paidDriverRateListQuery";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { ImoovaImage } from "@/app/Media/Components/Image";

export function UpdatePaidDriverRateDialog({
  rate,
}: {
  rate: PaidDriverRateListItem;
}) {
  const { mutateAsync } = useGqlMutation(updatePaidDriverRate);
  const { close } = useDialogState();

  return (
    <GenericDialog title="Paid Driver Rate">
      <GenericForm
        onSubmit={async (values) => {
          await mutateAsync({
            id: rate.id,
            input: values,
          });

          close();
        }}
        initialValues={{
          amount: rate.amount,
        }}
      >
        <div className="col-span-full">
          <ImoovaImage
            className="w-full"
            media={rate.trip.landscape_image}
            alt="trip image"
          />

          <div className="space-x-3">
            <span>{rate.trip.distance}km</span>
            <span className="inline-block size-2 rounded-full bg-gray-400" />
            <span>{rate.trip.duration}hrs</span>
          </div>
        </div>

        <FormDivider />

        <CurrencyInput name="amount" label="Amount" currency={rate.currency} />

        <DialogSubmitButton text="Update" />
      </GenericForm>
    </GenericDialog>
  );
}
