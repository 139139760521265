import { createRoute, Outlet } from "@tanstack/react-router";
import { appLayoutRoute } from "@/routes";
import { QuoteTableScreen } from "@/app/Quotes/Screens/QuoteTableScreen";
import { QuoteRecordScreen } from "@/app/Quotes/Screens/QuoteRecordScreen";
import { QuoteDetailScreen } from "@/app/Quotes/Screens/QuoteDetailScreen";
import { QuoteActivityScreen } from "@/app/Quotes/Screens/QuoteActivityScreen";
import {
  fetchQuery,
  prefetchInfiniteQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { quoteRecordQuery } from "@/app/Quotes/GraphQL/quoteRecordQuery";
import { z } from "zod";
import { QuoteStatus } from "@/gql/graphql";
import { QuoteInvoicesScreen } from "@/app/Quotes/Screens/QuoteInvoicesScreen";
import { quoteListQuery } from "@/app/Quotes/GraphQL/quoteListQuery";
import { getQuoteListQueryVariables } from "@/app/Quotes/Utils/getQuoteListQueryVariables";

const quoteListSearchParams = z.object({
  quoteStatus: z.array(z.nativeEnum(QuoteStatus)).optional().catch(undefined),
});

export type QuoteListSearchParams = z.infer<typeof quoteListSearchParams>;

export const quotesRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/quotes",
  component: Outlet,
});

export const quoteTableRoute = createRoute({
  getParentRoute: () => quotesRoute,
  path: "/",
  component: QuoteTableScreen,
  validateSearch: quoteListSearchParams,
  loaderDeps: ({ search: { quoteStatus } }) => ({
    quoteStatus,
  }),
});

export const quoteRecordLayoutRoute = createRoute({
  getParentRoute: () => quotesRoute,
  id: "_quoteRecordLayout",
  component: Outlet,
  loader: ({ deps, context: { auth } }) => {
    return prefetchInfiniteQuery(
      quoteListQuery,
      getQuoteListQueryVariables(deps),
      auth,
    );
  },
});

export const quoteRecordRoute = createRoute({
  getParentRoute: () => quoteRecordLayoutRoute,
  path: "$quoteId",
  component: QuoteRecordScreen,
  loader: ({ params: { quoteId }, context: { auth } }) => {
    return fetchQuery(quoteRecordQuery, { id: quoteId }, auth);
  },
});

export const quoteDetailRoute = createRoute({
  getParentRoute: () => quoteRecordRoute,
  path: "/",
  component: QuoteDetailScreen,
});

export const quoteActivityRoute = createRoute({
  getParentRoute: () => quoteRecordRoute,
  path: "/activity",
  component: QuoteActivityScreen,
});

export const quoteInvoicesRoute = createRoute({
  getParentRoute: () => quoteRecordRoute,
  path: "/invoices",
  component: QuoteInvoicesScreen,
});
