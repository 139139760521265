import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import {
  QuoteListItem,
  quoteListQuery,
} from "@/app/Quotes/GraphQL/quoteListQuery";
import { ModelLinkCell } from "@/lib/Components/DataTable/Cells/ModelLinkCell";
import { ReactNode, useEffect, useState } from "react";
import { Badge } from "@/components/catalyst/badge";
import { Link } from "@/components/catalyst/link";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { DeEmphasisedCell } from "@/lib/Components/DataTable/DeEmphasisedCell";
import { formatDate } from "@/lib/Formatters/formatDate";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { useQuoteActions } from "@/app/Quotes/hooks/useQuoteActions";
import {
  MultiSelectFilter,
  MultiSelectFilterOption,
} from "@/lib/Components/DataTable/Filters/MultiSelectFilter";
import { QuoteListQueryVariables, QuoteStatus } from "@/gql/graphql";
import { TableId } from "@/app/Common/Utils/tableIds";

type QuoteTableProps = {
  queryVariables?: Partial<QuoteListQueryVariables>;
  id: TableId;
  // hiddenColumns?: InvoiceTableColumnId[];
  buttons?: ReactNode;

  //Uncontrolled
  defaultEnabledStatuses?: QuoteStatus[];

  //Controlled
  statuses?: QuoteStatus[];
  onStatusChange?: (status: QuoteStatus[]) => void;
};

const quoteFilters: MultiSelectFilterOption<QuoteStatus>[] = [
  {
    label: "Accepted",
    value: QuoteStatus.Accepted,
  },
  {
    label: "Draft",
    value: QuoteStatus.Draft,
  },
  {
    label: "Expired",
    value: QuoteStatus.Expired,
  },
  {
    label: "Rejected",
    value: QuoteStatus.Rejected,
  },
  {
    label: "Issued",
    value: QuoteStatus.Issued,
  },
  {
    label: "Invoiced",
    value: QuoteStatus.Invoiced,
  },
];

export function QuoteTable({
  buttons,
  defaultEnabledStatuses = [],
  statuses,
  onStatusChange,
}: QuoteTableProps) {
  const getActions = useQuoteActions();

  const [localStatus, setStatus] = useState<QuoteStatus[]>(
    statuses ?? defaultEnabledStatuses,
  );

  const status = statuses ?? localStatus;

  useEffect(() => {
    onStatusChange?.(localStatus);
  }, [localStatus]);

  const columns: DataTableColDef<QuoteListItem, any, any>[] = [
    {
      id: "quote",
      accessorFn: (data) => data.reference,
      header: "Quote",
      cell: ({ row }) => (
        <ModelLinkCell
          to={"/quotes/$quoteId"}
          params={{
            quoteId: row.original.id,
          }}
        >
          {row.original.reference}
        </ModelLinkCell>
      ),
    },
    {
      id: "status",
      header: "Status",
      accessorFn: (row) => row.status,
      cell: ({ row }) => {
        return <Badge>{row.original.status}</Badge>;
      },
    },
    {
      id: "payee",
      header: "Payee",
      cell: ({ row }) => (
        <div>
          <p>
            <Link
              className="text-blue-500 underline"
              to={"/contacts/$id"}
              params={{
                id: row.original.payee.id,
              }}
              search={{}}
            >
              {row.original.payee.name}
            </Link>
          </p>
        </div>
      ),
    },
    {
      id: "amount",
      header: "Amount",
      accessorFn: (row) => formatCurrency(row.amount_inc_tax, row.currency),
      cell: DeEmphasisedCell,
    },
    {
      id: "due_date",
      header: "Due Date",
      accessorFn: (row) => formatDate(row.expiry_date),
      cell: DeEmphasisedCell,
    },
    {
      id: "created_at",
      header: "Created",
      accessorFn: (row) => formatDateTimeSinceNow(row.created_at),
      cell: DeEmphasisedCell,
    },
  ];

  return (
    <DataTable
      columns={columns}
      document={quoteListQuery}
      filters={(table) => (
        <div className="space-x-2">
          <MultiSelectFilter
            label="Status"
            options={quoteFilters}
            selected={status}
            onChange={(newStatus) => {
              setStatus(newStatus);
              table.setPagination((prev) => ({
                ...prev,
                pageIndex: 0,
              }));
            }}
          />
        </div>
      )}
      getQueryVariables={({ pagination, sorting, search }) => {
        return {
          search,
          status: status.length ? status : undefined,
          first: pagination.pageSize,
          page: pagination.pageIndex,
        };
      }}
      accessor={(data) => data.quotes}
      id={"quotes"}
      rightButtons={buttons}
      getActions={getActions}
    />
  );
}
