import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type SupplierOfficeListItem = ResultOf<
  typeof supplierOfficeListQuery
>["supplierOffices"]["data"][number];

export const supplierOfficeListQuery = graphql(`
  query SupplierOfficeList(
    $first: Int!
    $page: Int
    $search: String
    $supplier_id: ID
    $orderBy: [QuerySupplierOfficesOrderByRelationOrderByClause!]
    $archived: Boolean
  ) {
    supplierOffices(
      first: $first
      page: $page
      search: $search
      supplier_id: $supplier_id
      orderBy: $orderBy
      archived: $archived
    ) {
      data {
        id
        name
        code
        address {
          id
          name
          lat
          lng
          place_id
          address_line_1
          address_line_2
          formatted_address_line_1
          formatted_address_line_2
          postcode
          city
          state
          country
          locationMedia {
            ...MediaFields
          }
        }
        city {
          id
          name
          region
          state
        }
        created_at
        updated_at
        supplier {
          id
          name
          hire_unit_type
          measurement
          currency
        }
        archived_at
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
