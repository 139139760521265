import * as React from "react";
import { ComponentPropsWithoutRef, ReactNode, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { cn } from "@/lib/utils";
import { XMarkIcon } from "@heroicons/react/24/outline";

export type ComboboxProps<T> = {
  search: string;
  onSearchChange: (search: string) => void;

  items: T[] | undefined;
  onChange: (item: T) => Promise<void> | void;
  selected: T | null;
  getKey: (item: T) => string;

  autoFocus?: boolean;
  placeholder?: ReactNode;

  getListItemNode: (item: T) => ReactNode;
  getInputNode: (item: T) => ReactNode;

  fixture?: (search: string) => {
    label: string;
    onClick: () => Promise<T> | T;
  };

  isLoading?: boolean;

  commandProps?: ComponentPropsWithoutRef<typeof Command>;
  buttonProps?: ComponentPropsWithoutRef<typeof Button>;
  buttonRef?: React.RefObject<HTMLButtonElement>;

  clearable?: boolean;
  onClear?: () => void;
  disabled?: boolean;
};
export function Combobox<T>({
  search,
  onSearchChange,
  onChange,
  selected,
  autoFocus = false,
  placeholder = "Select...",

  items = [],
  fixture,
  isLoading = false,

  getListItemNode,
  getInputNode,

  commandProps,
  buttonProps,
  buttonRef,
  getKey,

  clearable,
  onClear,

  disabled,
}: ComboboxProps<T>) {
  const [open, setOpen] = useState(false);
  const [isFixtureLoading, setIsFixtureLoading] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          autoFocus={autoFocus}
          ref={buttonRef}
          onClick={(event) => {
            event.preventDefault();
            setOpen(true);
          }}
          onFocus={() => {
            if (!hasOpened && !open) {
              setHasOpened(true);
              setOpen(true);
            }
          }}
          {...buttonProps}
          className={cn(
            "h-[38px] w-full justify-between",
            buttonProps?.className,
          )}
        >
          {selected ? (
            <span className="truncate">{getInputNode(selected)}</span>
          ) : (
            <span className="text-xs text-gray-500">{placeholder}</span>
          )}

          <span className="-mr-3 ml-2 rounded-md p-1 hover:bg-gray-200">
            {isLoading ? (
              <Spinner className="h-4 w-4 shrink-0 opacity-50" />
            ) : (
              <>
                {clearable && selected ? (
                  <XMarkIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      onClear?.();
                    }}
                    className="h-4 w-4 shrink-0 opacity-50"
                  />
                ) : (
                  <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
                )}
              </>
            )}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0" align="start">
        <Command
          className="flex h-60 flex-col"
          shouldFilter={false}
          onKeyDown={(event) => {
            if (event.code === "Tab") {
              setOpen(false);
            }
          }}
          {...commandProps}
        >
          <CommandInput
            isLoading={isLoading}
            placeholder="Search..."
            onValueChange={onSearchChange}
            value={search}
          />
          <CommandEmpty>
            {search && !isLoading
              ? "No results found"
              : "Start typing to search"}
          </CommandEmpty>
          <CommandList>
            <CommandGroup className="overflow-auto">
              {items.map((item: T) => {
                return (
                  <CommandItem
                    key={getKey(item)}
                    value={getKey(item)}
                    onSelect={async () => {
                      await onChange(item);
                      setOpen(false);
                    }}
                  >
                    <div className="mr-2 flex-shrink-0">
                      <Check
                        className={cn(
                          "h-4 w-4",
                          selected !== null && getKey(item) === getKey(selected)
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                      />
                    </div>
                    {getListItemNode(item)}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {fixture && search ? (
              <CommandGroup className="flex-shrink-0">
                <CommandItem
                  onSelect={async () => {
                    const obj = fixture(search);

                    try {
                      setIsFixtureLoading(true);
                      const res = await obj?.onClick();
                      onSearchChange("");

                      setOpen(false);
                      onChange(res);
                    } catch (e) {
                    } finally {
                      setIsFixtureLoading(false);
                    }
                  }}
                  className="text-blue-500"
                >
                  {isFixtureLoading ? (
                    <Spinner className="h-4 w-4" />
                  ) : (
                    <PlusIcon className="h-4 w-4" />
                  )}
                  <span className="ml-2">{fixture(search).label}</span>
                </CommandItem>
              </CommandGroup>
            ) : null}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
