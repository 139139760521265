import { PaidDriverRates } from "@/app/PaidDrivers/PaidDrivers/Components/PaidDriverRates";
import { Currency } from "@/gql/graphql";
import { Suspense, useState } from "react";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { Heading } from "@/components/catalyst/heading";
import { Field } from "@/components/catalyst/fieldset";
import { Select } from "@/components/catalyst/select";
import { Button } from "@/components/catalyst/button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { CreatePaidDriverRateDialog } from "@/app/PaidDrivers/PaidDrivers/Components/CreatePaidDriverRateDialog";

export function PaidDriverRateScreen() {
  const [currency, setCurrency] = useState(Currency.Aud);
  const { open } = useDialog(CreatePaidDriverRateDialog);

  return (
    <div className="space-y-12">
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Paid Driver Rates</Heading>
        <div className="flex gap-4">
          <Field>
            <Select
              name="currency"
              onChange={(event) => {
                setCurrency(event.target.value as Currency);
              }}
            >
              {Object.values(Currency).map((r) => (
                <option key={r} value={r}>
                  {r}
                </option>
              ))}
            </Select>
          </Field>

          <Button
            onClick={() => {
              open({
                currency,
              });
            }}
          >
            Add rate
          </Button>
        </div>
      </div>

      <Suspense
        fallback={
          <div className="flex h-full w-full items-center justify-center">
            <Spinner />
          </div>
        }
      >
        <PaidDriverRates currency={currency} />
      </Suspense>
    </div>
  );
}
