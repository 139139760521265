import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { Link } from "@/components/catalyst/link";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { Heading } from "@/components/catalyst/heading";
import { SupplierIcon } from "@/app/Suppliers/Components/SupplierIcon";
import { QuerySuppliersOrderByColumn, SortOrder } from "@/gql/graphql";
import { Input, InputGroup } from "@/components/catalyst/input";
import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import { useState } from "react";
import {
  Pagination,
  PaginationNext,
  PaginationPrevious,
} from "@/components/catalyst/pagination";
import { supplierListQuery } from "@/app/Suppliers/GraphQL/supplierListQuery";
import { keepPreviousData } from "@tanstack/react-query";

export function ImportSelectSupplierScreen() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const { data } = useGqlQuery(
    supplierListQuery,
    {
      page,
      first: 20,
      search: search ? search : undefined,
      orderBy: search
        ? undefined
        : [
            {
              order: SortOrder.Asc,
              column: QuerySuppliersOrderByColumn.Name,
            },
          ],
    },
    {
      placeholderData: keepPreviousData,
    },
  );

  return (
    <FullPageScreen className="space-y-6">
      <Heading>Select Supplier</Heading>

      <InputGroup>
        <MagnifyingGlassIcon />
        <Input
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          name="search"
          placeholder="Search&hellip;"
          aria-label="Search"
        />
      </InputGroup>

      <ul className="space-y-3">
        {data?.suppliers.data.map((supplier) => (
          <li key={supplier.id}>
            <Link
              to="/import/$supplierId"
              params={{
                supplierId: supplier.id,
              }}
              className="flex items-center space-x-3 rounded-md p-3 transition-colors hover:bg-gray-100"
            >
              <SupplierIcon model={supplier} className="size-10" />
              <span className="flex-grow">{supplier.name}</span>
              <span>&rarr;</span>
            </Link>
          </li>
        ))}

        <li className="empty-state hidden py-6 text-center italic text-gray-500 [ul:not(:has(li:not(.empty-state)))_&]:block">
          No suppliers found.
        </li>
      </ul>

      {data?.suppliers.data.length ? (
        <Pagination>
          <PaginationPrevious
            disabled={data.suppliers.paginatorInfo.currentPage !== 0}
            onClick={() => {
              setPage(page - 1);
            }}
          >
            Previous
          </PaginationPrevious>
          <PaginationNext
            disabled={!data.suppliers.paginatorInfo.hasMorePages}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            Next
          </PaginationNext>
        </Pagination>
      ) : null}
    </FullPageScreen>
  );
}
