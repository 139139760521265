import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type PaidDriverRateListItem = ResultOf<
  typeof paidDriverRateListQuery
>["paidDriverRates"]["data"][number];
export const paidDriverRateListQuery = graphql(`
  query PaidDriverRates(
    $first: Int!
    $page: Int!
    $where: QueryPaidDriverRatesWhereWhereConditions
    $whereOriginCity: QueryPaidDriverRatesWhereOriginCityWhereHasConditions
  ) {
    paidDriverRates(
      first: $first
      page: $page
      where: $where
      whereOriginCity: $whereOriginCity
    ) {
      data {
        id
        currency
        amount
        destinationCity {
          id
          name
        }
        originCity {
          id
          name
        }
        trip {
          id
          distance
          duration
          landscape_image {
            ...MediaFields
          }
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
