import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { SortableList } from "@/lib/Components/Sortable/SortableList";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import {
  createDatoTripArticleMutation,
  datoTripArticleListQuery,
  deleteDatoTripArticleMutation,
  sortDatoTripArticleMutation,
} from "@/app/Relocations/GraphQL/datoTripArticleListQuery";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { RelocationRecord } from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { PlusIcon } from "@heroicons/react/20/solid";
import { cn } from "@/lib/utils";
import {
  SortableItem,
  SortableItemDragHandle,
} from "@/lib/Components/Sortable/SortableItem";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { BookMinusIcon } from "lucide-react";

const articleSchema = z.object({
  data: z.object({
    allArticles: z.array(
      z.object({
        id: z.string(),
        slug: z.string(),
        seo: z.object({
          title: z.string().nullable(),
          image: z.object({
            url: z.string().nullable(),
            alt: z.string().nullable(),
          }),
        }),
      }),
    ),
  }),
});

export function RelocationArticleScreen() {
  const relocation = useRelocationRecord();
  const { data } = useSuspenseGqlQuery(datoTripArticleListQuery, {
    originCityId: relocation.departureCity.id,
    destinationCityId: relocation.deliveryCity.id,
  });
  const queryClient = useQueryClient();
  const [items, setItems] = useState(data.datoTripArticles);

  useEffect(() => {
    setItems(data.datoTripArticles);
  }, [data.datoTripArticles.length]);

  const { mutateAsync } = useGqlMutation(sortDatoTripArticleMutation, {
    onSuccess: () => {
      return;
    },
  });

  const { mutateAsync: deleteItem } = useGqlMutation(
    deleteDatoTripArticleMutation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (q) => q.queryKey.includes("DatoTripArticleListQuery"),
        });
      },
    },
  );

  const { data: datoArticles } = useQuery({
    queryKey: ["DatoArticles"],
    queryFn: async () => {
      try {
        const res = await fetch("https://graphql.datocms.com/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${import.meta.env.VITE_DATO_PUBLISHABLE_KEY}`,
          },
          body: JSON.stringify({
            query: `query {
                      allArticles(first: 100){
                        id
                        slug
                        seo{
                          title
                          image{
                            alt
                            url
                          }
                        }   
                      }
                    }`,
          }),
        });
        const response = await res.json();

        return articleSchema.parse(response);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <div className="space-y-12">
      <RecordScreenCard
        title={`Attached Articles (${data?.datoTripArticles?.length ?? 0})`}
      >
        {items.length === 0 ? (
          <EmptyState
            Icon={BookMinusIcon}
            header="No articles are attached to this relocation"
            subheader="Add an article to context to the customer"
          />
        ) : null}

        <SortableList
          items={items}
          onChange={(articles) => {
            mutateAsync({
              ids: articles.map((item) => item.id),
            });

            setItems(articles);
          }}
          renderItem={(item) => {
            //find the corresponding article
            const article = datoArticles?.data.allArticles.find(
              (a) => a.id === item.dato_article_id,
            );

            return (
              <SortableItem id={item.id}>
                <div className="relative">
                  <img
                    className="aspect-square object-cover"
                    src={article?.seo.image.url ?? ""}
                  />
                  <p className="text-center text-xs text-gray-500">
                    {article?.seo.title}
                  </p>

                  <div className="absolute left-0 top-0">
                    <IconButton
                      intent="danger"
                      Icon={TrashIcon}
                      onClick={() => {
                        return deleteItem({
                          id: item.id,
                        });
                      }}
                      tooltip="Delete"
                    />
                  </div>
                </div>
                <SortableItemDragHandle />
              </SortableItem>
            );
          }}
        />
      </RecordScreenCard>

      <RecordScreenCard title="Published Articles">
        <div className="grid grid-cols-4 gap-4">
          {datoArticles?.data.allArticles.map((article) => {
            const disabled =
              data.datoTripArticles.findIndex(
                (a) => a.dato_article_id === article.id,
              ) > -1;

            return (
              <ArticleBlock
                relocation={relocation}
                key={article.id}
                article={article}
                disabled={disabled}
              />
            );
          })}
        </div>
      </RecordScreenCard>
    </div>
  );
}

function ArticleBlock({
  article,
  disabled,
  relocation,
}: {
  article: z.infer<typeof articleSchema>["data"]["allArticles"][number];
  disabled: boolean;
  relocation: RelocationRecord;
}) {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useGqlMutation(
    createDatoTripArticleMutation,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({
          predicate: (q) => q.queryKey.includes("DatoTripArticleListQuery"),
        });
      },
    },
  );

  return (
    <button
      disabled={isPending || disabled}
      onClick={async () => {
        await mutateAsync({
          input: {
            dato_article_id: article.id,
            destination_city_id: relocation.deliveryCity.id,
            origin_city_id: relocation.departureCity.id,
          },
        });
      }}
      className={cn(
        "group relative overflow-hidden rounded-md border border-gray-200 shadow-md",
        "transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-lg",
        // Disabled state styles
        (isPending || disabled) &&
          "cursor-not-allowed opacity-50 hover:scale-100 hover:shadow-md",
        // Prevent hover effects when disabled
        !(isPending || disabled) && "hover:scale-105 hover:shadow-lg",
      )}
      key={article.id}
    >
      <img
        className={cn(
          "aspect-square w-full object-cover",
          (isPending || disabled) && "opacity-60",
        )}
        src={article.seo.image.url ?? ""}
        alt={article.seo.image.alt ?? ""}
      />

      {isPending ? (
        <div className="absolute inset-0 flex items-center justify-center bg-black/0 transition-colors duration-300 group-hover:bg-black/30">
          <Spinner />
        </div>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center bg-black/0 transition-colors duration-300 group-hover:bg-black/30">
          <PlusIcon
            className={cn(
              "h-8 w-8 text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100",
              (isPending || disabled) && "opacity-0",
            )}
          />
        </div>
      )}

      <p
        className={cn(
          "line-clamp-1 p-1 text-xs text-gray-500",
          (isPending || disabled) && "text-gray-400",
        )}
      >
        {article.seo.title}
      </p>
    </button>
  );
}
