import {
  MediaCollection,
  ModelType,
  SupplierTermsAndConditionsType,
  UpdateSupplierInsuranceInput,
} from "@/gql/graphql";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { createMediaMutation } from "@/app/Media/GraphQL/mediaMutations";
import { PdfUploader } from "@/lib/FileUpload/PdfUploader";
import { updateSupplierTermsAndConditionsMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { toast } from "sonner";
import { SettingFormCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import { supplierTermsAndConditionsRecord } from "@/app/Suppliers/GraphQL/supplierTermsAndConditionsRecord";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { PdfViewer } from "@/lib/Components/PdfViewer";

export function TermsAndConditionsSettingsCard({
  supplierId,
}: {
  supplierId: string;
}) {
  const { mutateAsync } = useGqlMutation(
    updateSupplierTermsAndConditionsMutation,
  );

  const { data } = useSuspenseGqlQuery(supplierTermsAndConditionsRecord, {
    supplierId,
  });

  return (
    <SettingFormCard<UpdateSupplierInsuranceInput>
      title="Rental Terms & Conditions"
      description="Add your rental terms and conditions here. This will be displayed to the customer when they book a vehicle."
      onSubmit={(values) => {
        return mutateAsync({
          input: values,
        });
      }}
      initialValues={{
        supplier_id: supplierId,
        type:
          data.supplierTermsAndConditions?.type ??
          SupplierTermsAndConditionsType.Pdf,
        text: data.supplierTermsAndConditions?.text,
      }}
    >
      <TermsAndConditionsTabForm />
    </SettingFormCard>
  );
}

export function TermsAndConditionsTabForm() {
  const { setFieldValue, values } = useForm<UpdateSupplierInsuranceInput>();
  return (
    <Tabs
      className="col-span-full w-full"
      value={values.type}
      onValueChange={async (newVal) => {
        await setFieldValue("type", newVal);
      }}
    >
      <TabsList className="mb-6 w-full">
        <TabsTrigger
          className="w-full"
          value={SupplierTermsAndConditionsType.Pdf}
        >
          PDF
        </TabsTrigger>
        <TabsTrigger
          className="w-full"
          value={SupplierTermsAndConditionsType.Text}
        >
          Text
        </TabsTrigger>
      </TabsList>

      <TabsContent value={SupplierTermsAndConditionsType.Pdf}>
        <TermsAndConditionsPdf />
      </TabsContent>
      <TabsContent value={SupplierTermsAndConditionsType.Text}>
        <RichTextInput
          name="text"
          label="Rental terms & Conditions"
          className="col-span-full"
        />
      </TabsContent>
    </Tabs>
  );
}

function TermsAndConditionsPdf() {
  const { mutateAsync: createMedia } = useGqlMutation(createMediaMutation);

  const { values } = useForm<UpdateSupplierInsuranceInput>();
  const { data, refetch } = useSuspenseGqlQuery(
    supplierTermsAndConditionsRecord,
    {
      supplierId: values.supplier_id,
    },
  );

  return (
    <div className="col-span-full mb-8">
      <div className="mt-2 space-y-6">
        {data.supplierTermsAndConditions?.pdf_url ? (
          <PdfViewer url={data.supplierTermsAndConditions.pdf_url} />
        ) : null}

        <PdfUploader
          onUpload={async (uploads) => {
            if (uploads.length === 0 || uploads.length > 1) {
              throw new Error("Uploads must be exactly 1");
            }
            const upload = uploads[0];
            try {
              await createMedia({
                input: {
                  file_name: upload.file.name,
                  key: upload.temporary_url,
                  collectionName: MediaCollection.TermsAndConditionsPdf,
                  objectType: ModelType.SupplierTermsAndConditions,
                  objectId: data.supplierTermsAndConditions!.id,
                },
              });

              await refetch();
            } catch (err: any) {
              console.error(err);
              toast.warning("PDF upload failed");
            }
          }}
        />
      </div>
    </div>
  );
}
