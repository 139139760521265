import { createRoute } from "@tanstack/react-router";
import { PaidDriverRecordScreen } from "@/app/PaidDrivers/PaidDrivers/Screens/PaidDriverRecordScreen";
import { PaidDriverTableScreen } from "@/app/PaidDrivers/PaidDrivers/Screens/PaidDriverTableScreen";
import { PaidDriverDetailScreen } from "@/app/PaidDrivers/PaidDrivers/Screens/PaidDriverDetailScreen";
import { appLayoutRoute } from "@/routes";
import { z } from "zod";
import { prefetchInfiniteQuery } from "@/lib/GraphQLCodegen/fetcher";
import { paidDriverListQuery } from "@/app/PaidDrivers/PaidDrivers/GraphQL/paidDriverListQuery";
import { getPaidDriverQueryVariables } from "@/app/PaidDrivers/PaidDrivers/Utils/getPaidDriverQueryVariables";
import { PaidDriverLayout } from "@/app/PaidDrivers/PaidDrivers/Screens/PaidDriverLayout";
import { PaidDriverQuotes } from "@/app/PaidDrivers/PaidDrivers/Screens/PaidDriverQuotes";
import { PaidDriverRateScreen } from "@/app/PaidDrivers/PaidDrivers/Screens/PaidDriverRateScreen";

const paidDriverSearchParams = z.object({
  paidDriverSearch: z.string().optional(),
});
export type PaidDriverListSearchParams = z.infer<typeof paidDriverSearchParams>;

export const paidDriversLayout = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/paid-drivers",
  component: PaidDriverLayout,
});

export const paidDriverRatesRoute = createRoute({
  getParentRoute: () => paidDriversLayout,
  path: "/rates",
  component: PaidDriverRateScreen,
});

export const paidDriverQuotesRoute = createRoute({
  getParentRoute: () => paidDriversLayout,
  path: "/quotes",
  component: PaidDriverQuotes,
});

export const paidDriverTableRoute = createRoute({
  getParentRoute: () => paidDriversLayout,
  path: "/drivers",
  validateSearch: paidDriverSearchParams,
  loaderDeps: ({ search }) => search,
  loader: ({ deps, context: { auth } }) => {
    return prefetchInfiniteQuery(
      paidDriverListQuery,
      getPaidDriverQueryVariables(deps),
      auth,
    );
  },
  component: PaidDriverTableScreen,
});

export const paidDriverRoute = createRoute({
  getParentRoute: () => paidDriverTableRoute,
  path: "$id",
  component: PaidDriverRecordScreen,
});

export const paidDriverDetailRoute = createRoute({
  getParentRoute: () => paidDriverRoute,
  path: "/",
  component: PaidDriverDetailScreen,
});
