import { Currency, QueryPaidDriverRatesWhereColumn } from "@/gql/graphql";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { paidDriverRateListQuery } from "@/app/PaidDrivers/PaidDrivers/GraphQL/paidDriverRateListQuery";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { CreatePaidDriverRateDialog } from "@/app/PaidDrivers/PaidDrivers/Components/CreatePaidDriverRateDialog";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { cn } from "@/lib/utils";
import { CityListItem } from "@/app/Cities/Cities/GraphQL/cityListQuery";
import { Fragment, useMemo } from "react";
import { MapPinPlusIcon } from "lucide-react";
import { UpdatePaidDriverRateDialog } from "@/app/PaidDrivers/PaidDrivers/Components/UpdatePaidDriverRateDialog";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { TrashIcon } from "@heroicons/react/24/outline";
import { deletePaidDriverRate } from "@/app/PaidDrivers/PaidDrivers/GraphQL/paidDriverRateMutation";

export function PaidDriverRates({ currency }: { currency: Currency }) {
  const { open } = useDialog(CreatePaidDriverRateDialog);
  const { open: updateRate } = useDialog(UpdatePaidDriverRateDialog);
  const { mutateAsync: deleteRate } = useGqlMutation(deletePaidDriverRate);

  const { data } = useSuspenseGqlQuery(paidDriverRateListQuery, {
    page: 1,
    first: 200,
    where: {
      column: QueryPaidDriverRatesWhereColumn.Currency,
      value: currency,
    },
  });

  // Calculate value-to-distance ratios and determine relative color ranges
  const { getRelativeValueColor } = useMemo(() => {
    // Calculate all valid ratios
    const ratios = data.paidDriverRates.data
      .filter((rate) => rate.trip.distance > 0)
      .map((rate) => ({
        id: `${rate.originCity.id}-${rate.destinationCity.id}`,
        ratio: rate.amount / rate.trip.distance,
      }));

    // Calculate the median
    const sortedRatios = [...ratios].sort((a, b) => a.ratio - b.ratio);
    const medianRatio =
      sortedRatios[Math.floor(sortedRatios.length / 2)]?.ratio || 0;

    // Define outlier thresholds (±50% of the median)
    const lowerThreshold = medianRatio * 0.5;
    const upperThreshold = medianRatio * 1.5;

    // Function to get color based on relative position in the range
    const getRelativeValueColor = (amount: number, distance: number) => {
      if (!distance) return "bg-gray-100 text-gray-800";

      const ratio = amount / distance;

      // Check if this is an outlier
      if (ratio < lowerThreshold) return "bg-red-100 text-red-900"; // Below range (outlier low)
      if (ratio > upperThreshold) return "bg-blue-100 text-blue-900"; // Above range (outlier high)

      // Calculate position in the range (0 to 1)
      const normalizedPosition = Math.max(
        0,
        Math.min(
          1,
          (ratio - lowerThreshold) / (upperThreshold - lowerThreshold || 1),
        ),
      );

      // Map the normalized position to green-100 to green-400
      const greenShade = Math.floor(normalizedPosition * 3) * 100 + 100;

      return `bg-green-${greenShade} text-green-900`;
    };

    return { getRelativeValueColor };
  }, [data.paidDriverRates.data]);

  const cities = new Map();
  data.paidDriverRates.data.forEach((rate) => {
    if (!cities.has(rate.originCity.id)) {
      cities.set(rate.originCity.id, rate.originCity);
    }
  });

  data.paidDriverRates.data.forEach((rate) => {
    if (!cities.has(rate.destinationCity.id)) {
      cities.set(rate.destinationCity.id, rate.destinationCity);
    }
  });

  const sortedCities = [...(cities.values() as MapIterator<CityListItem>)].sort(
    (a, b) => a.name.localeCompare(b.name),
  );

  return (
    <>
      {data.paidDriverRates.data.length === 0 ? (
        <EmptyState currency={currency} />
      ) : (
        <div className="h-full w-full overflow-auto rounded-md">
          <div
            className="grid border border-gray-200"
            style={{
              gridTemplateColumns: `8rem repeat(${sortedCities.length}, minmax(5rem, 1fr))`,
              gridTemplateRows: `3rem repeat(${sortedCities.length}, 3rem)`,
            }}
          >
            <div
              className={cn(
                "sticky left-0 top-0 z-50 flex select-none items-center justify-center bg-white ring-1 ring-gray-200",
              )}
            >
              <div className="relative h-full w-full text-xs">
                <span className="absolute right-1 top-1">Destination</span>
                <span className="absolute bottom-1 left-1">Origin</span>
              </div>
            </div>

            {/* Column headings */}
            {sortedCities.map((city, index) => {
              return (
                <div
                  key={`col-${city.id}-${index}`}
                  className="sticky top-0 flex items-center justify-center bg-white px-2 text-sm ring-1 ring-gray-200"
                >
                  {city?.name}
                </div>
              );
            })}

            {sortedCities.map((originCity, rowIndex) => {
              return (
                <Fragment key={`row-${originCity.id}-${rowIndex}`}>
                  {/* Row headings */}
                  <div className="sticky left-0 z-20 flex items-center justify-center gap-1 bg-white px-2 text-sm ring-1 ring-gray-200">
                    {originCity?.name}
                  </div>

                  {Array.from({
                    length: sortedCities.length,
                  }).map((_, colIndex) => {
                    //Find the destination city
                    const destinationCity = sortedCities.at(colIndex);

                    if (!destinationCity) {
                      throw new Error("No destination city found");
                    }

                    //Find the rate
                    const rate =
                      data.paidDriverRates.data.find((rate) => {
                        return (
                          rate.originCity.id === originCity.id &&
                          rate.destinationCity.id === destinationCity.id
                        );
                      }) ?? null;

                    if (!rate) {
                      return (
                        <button
                          className="flex items-center justify-center bg-gray-50 text-xs text-gray-500 ring-1 ring-gray-200 hover:bg-gray-100"
                          onClick={() => {
                            open({
                              currency,
                              initialValues: {
                                origin_city_id: originCity.id,
                                destination_city_id: destinationCity.id,
                              },
                            });
                          }}
                          key={`cell-empty-${originCity.id}-${destinationCity.id}-${rowIndex}-${colIndex}`}
                        >
                          --
                        </button>
                      );
                    }

                    // Calculate relative value color
                    const valueColor = getRelativeValueColor(
                      rate.amount,
                      rate.trip.distance,
                    );

                    return (
                      <button
                        key={`cell-${rate.id}-${originCity.id}-${destinationCity.id}-${rowIndex}-${colIndex}`}
                        className={cn(
                          `group relative flex flex-col items-center justify-center p-1 text-xs ring-1 ring-gray-200 transition-colors hover:opacity-90`,
                          valueColor,
                        )}
                        onClick={() => {
                          updateRate({
                            rate,
                          });
                        }}
                        title={`Value/Distance: ${(rate.amount / (rate.trip.distance || 1)).toFixed(2)}`}
                      >
                        <span className="font-medium">
                          {formatCurrency(rate.amount, rate.currency)}
                        </span>
                        <span className="text-xs opacity-80">
                          {rate.trip.distance} km
                        </span>

                        <div className="invisible absolute right-1 group-hover:visible">
                          <IconButton
                            Icon={TrashIcon}
                            tooltip="delete"
                            intent="danger"
                            onClick={async (e) => {
                              e.stopPropagation();
                              return deleteRate({
                                id: rate.id,
                              });
                            }}
                          />
                        </div>
                      </button>
                    );
                  })}
                </Fragment>
              );
            })}
          </div>

          {/* Legend with actual values from the dataset */}
          <div className="mt-4 flex flex-wrap items-center gap-2 text-xs">
            <span className="font-medium">Value/Distance Ratio:</span>
            <div className="flex items-center gap-1">
              <div className="flex items-center">
                <div className="h-3 w-3 bg-green-100 ring-1 ring-gray-200"></div>
                <span className="ml-1">Low</span>
              </div>
              <div className="flex items-center">
                <div className="h-3 w-3 bg-green-200 ring-1 ring-gray-200"></div>
                <span className="ml-1">Medium</span>
              </div>
              <div className="flex items-center">
                <div className="h-3 w-3 bg-green-400 ring-1 ring-gray-200"></div>
                <span className="ml-1">High</span>
              </div>
            </div>
            <div className="ml-2 flex items-center gap-1">
              <div className="flex items-center">
                <div className="h-3 w-3 bg-blue-100 ring-1 ring-gray-200"></div>
                <span className="ml-1">Outlier (high)</span>
              </div>
              <div className="ml-2 flex items-center">
                <div className="h-3 w-3 bg-red-100 ring-1 ring-gray-200"></div>
                <span className="ml-1">Outlier (low)</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function EmptyState({ currency }: { currency: Currency }) {
  const { open } = useDialog(CreatePaidDriverRateDialog);

  return (
    <button
      type="button"
      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={() => {
        open({
          currency,
        });
      }}
    >
      <MapPinPlusIcon className="mx-auto size-12 text-gray-400" />

      <span className="mt-2 block text-sm font-semibold text-gray-900">
        Create a paid driver rate
      </span>
    </button>
  );
}
