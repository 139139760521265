import { graphql } from "@/gql";

export const supplierTermsAndConditionsRecord = graphql(`
  query SupplierTermsAndConditionsRecord($supplierId: ID!) {
    supplierTermsAndConditions(supplierId: $supplierId) {
      id
      type
      pdf_url
      text
    }
  }
`);
