import Dropzone from "react-dropzone";
import { useFileUpload } from "@/lib/FileUpload/Hooks/useFileUpload";
import { ComponentProps, useState } from "react";
import { cn } from "@/lib/utils";
import { UploadIcon } from "lucide-react";

export type Upload = { file: File; temporary_url: string };
type FileUploaderProps = {
  onDrop?: (files: File[]) => void;
  onUpload: (uploads: Upload[]) => Promise<void> | void;
  isDisabled?: boolean;
  className?: string;
  maxSize?: number;
  maxFileCount?: number;
} & Partial<ComponentProps<typeof Dropzone>>;
export function FileUploader({
  onUpload,
  onDrop,
  isDisabled,
  className,
  maxSize = 1024 * 1024 * 2,
  maxFileCount = 1,
  ...props
}: FileUploaderProps) {
  const { mutateAsync } = useFileUpload();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dropzone
      accept={{
        "image/jpeg": [],
        "image/png": [],
        "image/webp": [],
        "image/avif": [],
      }}
      onDrop={async (acceptedFiles) => {
        try {
          setIsLoading(true);
          onDrop?.(acceptedFiles);
          const files = await mutateAsync(acceptedFiles);
          await onUpload(files);
        } finally {
          setIsLoading(false);
        }
      }}
      disabled={isLoading}
      noClick={isLoading}
      {...props}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={cn(
            "border-muted-foreground/25 hover:bg-muted/25 group relative grid h-52 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed px-5 py-2.5 text-center transition",
            "ring-offset-background focus-visible:ring-ring focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
            isDragActive && "border-muted-foreground/50",
            isDisabled && "pointer-events-none opacity-60",
            className,
          )}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
              <div className="rounded-full border border-dashed p-3">
                <UploadIcon
                  className="text-muted-foreground size-7"
                  aria-hidden="true"
                />
              </div>
              <p className="text-muted-foreground font-medium">
                Drop the files here
              </p>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
              <div className="rounded-full border border-dashed p-3">
                <UploadIcon
                  className="text-muted-foreground size-7"
                  aria-hidden="true"
                />
              </div>
              <div className="flex flex-col gap-px">
                <p className="text-muted-foreground font-medium">
                  Drag {`'n'`} drop files here, or click to select files
                </p>
                <p className="text-muted-foreground/70 text-sm">
                  You can upload
                  {maxFileCount > 1
                    ? ` ${maxFileCount === Infinity ? "multiple" : maxFileCount}
                      files (up to ${formatBytes(maxSize)} each)`
                    : ` a file with ${formatBytes(maxSize)}`}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  );
}

function formatBytes(
  bytes: number,
  opts: {
    decimals?: number;
    sizeType?: "accurate" | "normal";
  } = {},
) {
  const { decimals = 0, sizeType = "normal" } = opts;

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const accurateSizes = ["Bytes", "KiB", "MiB", "GiB", "TiB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${
    sizeType === "accurate"
      ? (accurateSizes[i] ?? "Bytest")
      : (sizes[i] ?? "Bytes")
  }`;
}
