import { BookingStatus } from "@/gql/graphql";
import { z } from "zod";

export const cancelBookingValidation = z.object({
  status: z.nativeEnum(BookingStatus).refine((status) => {
    return [
      BookingStatus.AdminCancelled,
      BookingStatus.SupplierCancelled,
      BookingStatus.SupplierCancelledNotAvailable,
      BookingStatus.SupplierCancelledMechanicalFailure,
      BookingStatus.SupplierCancelledNoFerry,
      BookingStatus.CustomerCancelled,
      BookingStatus.CustomerCancelledNoShow,
      BookingStatus.CustomerCancelledRebooked,
      BookingStatus.CustomerCancelledConfirmationTimeliness,
    ].includes(status);
  }),
  reason: z.string().min(2).max(255),
});

export const confirmBookingValidation = z.object({
  supplier_reference: z.string().nullish(),
  customer_note: z.string().nullish(),
});

export const vipBookingValidation = z.object({
  customer_note: z.string().nullish(),
});

export const pendingBookingValidation = z.object({
  customer_note: z.string().nullish(),
});
