import { SupplierIcon } from "@/app/Suppliers/Components/SupplierIcon";
import {
  BaseComboboxInputProps,
  ComboboxInput,
} from "@/lib/Components/Form/Inputs/ComboboxInput";
import {
  SupplierListItem,
  supplierListQuery,
} from "@/app/Suppliers/GraphQL/supplierListQuery";
import { Link } from "@/components/catalyst/link";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

export function SupplierInput({
  name,
  label,
  ...props
}: BaseComboboxInputProps<
  ResultOf<typeof supplierListQuery>,
  VariablesOf<typeof supplierListQuery>,
  SupplierListItem,
  ResultOf<typeof supplierRecordQuery>,
  VariablesOf<typeof supplierRecordQuery>
>) {
  return (
    <ComboboxInput
      document={supplierListQuery}
      accessor={(data) => {
        return data.suppliers.data;
      }}
      recordDocument={supplierRecordQuery}
      recordAccessor={(data) => {
        return data.supplier;
      }}
      getQueryVariables={(search) => ({
        first: 20,
        page: 1,
        search,
      })}
      getKey={(item) => item.id}
      getInputNode={(item) => item.name}
      getListItemNode={(item) => <Node item={item} />}
      name={name}
      label={label}
      getViewNode={(item) =>
        item ? (
          <Link
            className="text-blue-500"
            to={"/suppliers/$id"}
            params={{
              id: item.id,
            }}
          >
            {item.name}
          </Link>
        ) : (
          "--"
        )
      }
      {...props}
    />
  );
}

function Node({ item }: { item: SupplierListItem }) {
  return (
    <div className="flex items-center space-x-2">
      <SupplierIcon className="size-6" model={item} />
      <p>{item.name}</p>
    </div>
  );
}
