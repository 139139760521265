import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export const quoteListQuery = graphql(`
  query QuoteList(
    $page: Int!
    $first: Int!
    $status: [QuoteStatus!]
    $search: String
    $where: QueryQuotesWhereWhereConditions
  ) {
    quotes(
      page: $page
      first: $first
      search: $search
      status: $status
      where: $where
    ) {
      data {
        id
        uuid
        reference
        status
        amount_inc_tax
        currency
        expiry_date
        created_at
        payee {
          ...ContactRecordFields
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export type QuoteListItem = ResultOf<
  typeof quoteListQuery
>["quotes"]["data"][number];
