import { graphql } from "@/gql";

export const datoTripArticleListQuery = graphql(`
  query DatoTripArticleListQuery($originCityId: ID!, $destinationCityId: ID!) {
    datoTripArticles(
      originCityId: $originCityId
      destinationCityId: $destinationCityId
    ) {
      id
      dato_article_id
      sort_order
      created_at
    }
  }
`);

export const createDatoTripArticleMutation = graphql(`
  mutation CreateDatoTripArticleMutation($input: CreateDatoTripArticle!) {
    createDatoTripArticle(input: $input) {
      id
    }
  }
`);

export const deleteDatoTripArticleMutation = graphql(`
  mutation DeleteDatoTripArticleMutation($id: ID!) {
    deleteDatoTripArticle(id: $id) {
      id
    }
  }
`);

export const sortDatoTripArticleMutation = graphql(`
  mutation SortDatoTripArticleMutation($ids: [ID!]!) {
    setDatoTripArticleSortOrder(ids: $ids)
  }
`);
