import { ReactNode, useState } from "react";
import { toast } from "sonner";
import { Button } from "@/components/catalyst/button";
import { AnimatePresence, motion } from "framer-motion";
import { CheckIcon, CopyCheckIcon, CopyIcon } from "lucide-react";

export function CopyToClipboard({
  text,
  children,
}: {
  text: string | null;
  children?: ReactNode;
}) {
  const [copied, setCopied] = useState(false);

  if (!text) return <div>--</div>;

  return (
    <button
      className="group/copy relative flex items-center space-x-2 whitespace-nowrap rounded-md text-left ring-gray-500 transition-colors hover:bg-gray-100"
      onClick={() => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        toast.success("Success", {
          description: `'${text}' to clipboard`,
        });

        setTimeout(() => {
          setCopied(false);
        }, 2000);
      }}
    >
      <span>{children ? children : text}</span>
      <span className="absolute -right-4 opacity-0 transition duration-300 group-hover/copy:opacity-100">
        {copied ? (
          <CopyCheckIcon className="h-3 w-3" />
        ) : (
          <CopyIcon className="h-3 w-3" />
        )}
      </span>
    </button>
  );
}

export function CopyToClipboardButton({ text }: { text: string }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    toast.success("Success", {
      description: `'${text}' to clipboard`,
    });

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Button plain onClick={handleCopy} className="relative h-8 w-8">
      <AnimatePresence mode="wait">
        {copied ? (
          <motion.div
            key="check"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.2 }}
            className="absolute inset-0 flex items-center justify-center"
          >
            <CheckIcon className="h-4 w-4 text-green-500" />
          </motion.div>
        ) : (
          <motion.div
            key="copy"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.2 }}
            className="absolute inset-0 flex items-center justify-center"
          >
            <CopyIcon className="h-4 w-4" />
          </motion.div>
        )}
      </AnimatePresence>
    </Button>
  );
}
