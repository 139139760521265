import { Document, Page, pdfjs } from "react-pdf";
import { memo, useCallback, useEffect, useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {
  Pagination,
  PaginationNext,
  PaginationPrevious,
} from "@/components/catalyst/pagination";
import { cn } from "@/lib/utils";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const maxWidth = 800;

export const PdfViewer = memo(PdfViewerComp, (o, n) => o.url === n.url);

function PdfViewerComp({ url }: { url: string }) {
  const [numPages, setNumPages] = useState<number>();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  useResizeObserver(containerRef, {}, onResize);

  return (
    <div
      ref={setContainerRef}
      className="relative mx-auto flex max-w-[800px] justify-center rounded-md border border-gray-200 p-6 shadow-md"
    >
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess} className="">
        {Array.from(new Array(numPages), (_el, index) => (
          <Page
            key={`page_${index + 1}`}
            className={cn("mx-auto", {
              hidden: pageNumber !== index + 1,
            })}
            pageNumber={index + 1}
            width={
              containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
            }
          />
        ))}
      </Document>
      <div className="absolute bottom-0 flex items-center justify-end">
        <Pagination>
          <PaginationPrevious
            disabled={pageNumber === 1}
            onClick={() => {
              setPageNumber(pageNumber - 1);
            }}
          />
          <PaginationNext
            disabled={pageNumber === numPages}
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
          />
        </Pagination>
      </div>
    </div>
  );
}

function useResizeObserver(
  element: Element | null,
  options: ResizeObserverOptions | undefined,
  observerCallback: ResizeObserverCallback,
): void {
  useEffect(() => {
    if (!element || !("ResizeObserver" in window)) {
      return undefined;
    }

    const observer = new ResizeObserver(observerCallback);

    observer.observe(element, options);

    return () => {
      observer.disconnect();
    };
  }, [element, options, observerCallback]);
}
