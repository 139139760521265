import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { InvoiceDetailsForm } from "@/app/Invoices/Forms/InvoiceDetailsForm";
import {
  CreateInvoiceInput,
  Currency,
  QueryInvoicesWhereColumn,
  QuoteActionFieldsFragment,
  SqlOperator,
} from "@/gql/graphql";
import {
  useGqlMutation,
  useGqlQuery,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { quoteRecordQuery } from "@/app/Quotes/GraphQL/quoteRecordQuery";
import { Suspense, useEffect, useState } from "react";
import { ContactListItem } from "@/app/Contacts/GraphQL/contactListQuery";
import { RecordCreateCard } from "@/lib/Components/SideBarList/Cards/RecordCreateCard";
import { InvoiceLineItemsForm } from "@/app/Invoices/Forms/InvoiceLineItemsForm";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { IllustrationsDocuments } from "@/assets/Illustrations";
import { createInvoiceMutation } from "@/app/Invoices/GraphQL/invoiceMutations";
import { useNavigate } from "@tanstack/react-router";
import { toast } from "sonner";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { invoiceListQuery } from "@/app/Invoices/GraphQL/invoiceListQuery";
import { Button } from "@/components/ui/button";

export function InvoiceCreateDialog({ id, status }: QuoteActionFieldsFragment) {
  const { data } = useSuspenseGqlQuery(quoteRecordQuery, { id: id });
  const { data: invoices } = useGqlQuery(invoiceListQuery, {
    where: {
      column: QueryInvoicesWhereColumn.QuoteId,
      operator: SqlOperator.Eq,
      value: id,
    },
    first: 1,
  });

  const [createMultiple, setCreateMultiple] = useState(false);

  const [contact, setContact] = useState<ContactListItem | null>(
    data.quote.payee,
  );
  const [currency, setCurrency] = useState<Currency | null>(
    data.quote.currency,
  );
  const { mutateAsync } = useGqlMutation(createInvoiceMutation);
  const navigate = useNavigate();
  const { close, isOpen } = useDialogState();

  useEffect(() => {
    setCreateMultiple(false);
  }, [isOpen]);

  return (
    <GenericDialog title={"Create Invoice"}>
      <Suspense>
        {createMultiple || invoices?.invoices.data.length === 0 ? (
          <RecordCreateCard<CreateInvoiceInput>
            initialValues={{
              quote: {
                connect: data.quote.id ?? null!,
              },
              payee: {
                connect: data.quote.payee.id ?? null!,
              },
              currency: data.quote.currency ?? null!,
              is_tax_included: false,
              purchase_order_number: "",
              lineItems: {
                upsert: data.quote.lineItems?.map((quoteLineItem) => {
                  return {
                    quantity: quoteLineItem.quantity,
                    unit_amount: quoteLineItem.unit_amount,
                    tax_type: quoteLineItem.tax_type,
                    description: quoteLineItem.description,
                  };
                }),
              },
            }}
            onSubmit={async (values) => {
              const invoice = await mutateAsync({
                input: values,
              });

              toast.success("Successfully created invoice", {
                action: {
                  label: "View",
                  onClick: async () => {
                    await navigate({
                      to: "/invoices/$id",
                      params: {
                        id: invoice.createInvoice.id,
                      },
                    });
                  },
                },
              });

              close();
            }}
          >
            <InvoiceDetailsForm
              setPayee={setContact}
              setCurrency={setCurrency}
            />

            <div className="col-span-full">
              {contact && currency ? (
                <InvoiceLineItemsForm currency={currency} payee={contact} />
              ) : (
                <EmptyState
                  Icon={IllustrationsDocuments}
                  header="Add line items"
                  subheader="Select a payee and currency you wish to add line items for"
                />
              )}
            </div>
          </RecordCreateCard>
        ) : (
          <div className="flex flex-col items-center justify-center gap-6">
            <h1>
              An invoice for this quote already exists. Do you want to create
              another?
            </h1>
            <Button
              className="max-w-2xl"
              onClick={() => setCreateMultiple(true)}
            >
              Create new invoice
            </Button>
          </div>
        )}
      </Suspense>
    </GenericDialog>
  );
}
