import { useMutation } from "@tanstack/react-query";
import { Visibility } from "@/gql/graphql";
import { toast } from "sonner";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createSignedMediaUploadUrl } from "@/app/Media/GraphQL/mediaMutations";

export type UploadedFile = {
  file: File;
  temporary_url: string;
};

export function useFileUpload() {
  const upload = useUpload();

  return useMutation({
    mutationKey: ["SignedS3Upload"],
    mutationFn: async (files: File[]) => {
      const uploads = files.map((file) => {
        return {
          promise: upload(file),
          file,
        };
      });

      uploads.forEach((upload) => {
        toast.promise(upload.promise, {
          description: upload.file.name,
          loading: "Uploading file...",
          success: "File uploaded!",
          error: "Error uploading file",
        });
      });

      const promises = uploads.map((upload) => upload.promise);

      return await Promise.all(promises);
    },
    onSuccess: () => {
      return;
    },
  });
}

function useUpload() {
  const controller = new AbortController();
  const { mutateAsync } = useGqlMutation(createSignedMediaUploadUrl, {
    onSuccess: () => {
      return;
    },
  });

  return async (file: File) => {
    const { createSignedStorageUrl } = await mutateAsync({
      input: {
        visibility: Visibility.Private,
      },
    });
    const headers = createSignedStorageUrl.headers;

    // Upload the file using fetch instead of axios
    const response = await fetch(createSignedStorageUrl.url, {
      method: "PUT",
      body: file,
      signal: controller.signal,
      headers: {
        ...headers,
        Host: undefined,
        "Content-Type": file.type,
      },
      // No need for withCredentials with fetch, it's handled differently
      credentials: "omit", // Equivalent to withCredentials: false
    });

    if (!response.ok) {
      throw new Error(`Upload failed with status: ${response.status}`);
    }

    return {
      abortController: controller,
      file,
      temporary_url: createSignedStorageUrl.key,
    };
  };
}
