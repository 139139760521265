import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { CreatePaidDriverRateInput, Currency } from "@/gql/graphql";
import { CityInput } from "@/app/Cities/Cities/Components/CityInput";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { createPaidDriverRate } from "@/app/PaidDrivers/PaidDrivers/GraphQL/paidDriverRateMutation";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { tripByCities } from "@/app/Cities/Trips/GraphQL/tripByCities";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { Suspense } from "react";
import { ImoovaImage } from "@/app/Media/Components/Image";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { Skeleton } from "@/components/ui/skeleton";

export function CreatePaidDriverRateDialog({
  currency,
  initialValues,
}: {
  currency: Currency;
  initialValues?: Partial<CreatePaidDriverRateInput>;
}) {
  const { mutateAsync } = useGqlMutation(createPaidDriverRate);
  const { close } = useDialogState();

  return (
    <GenericDialog title="Paid Driver Rate">
      <GenericForm
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });

          close();
        }}
        initialValues={{
          origin_city_id: "",
          destination_city_id: "",
          currency,
          amount: null!,
          ...initialValues,
        }}
      >
        <Form />
        <DialogSubmitButton text="Create" />
      </GenericForm>
    </GenericDialog>
  );
}

function Form() {
  const { values } = useForm<CreatePaidDriverRateInput>();

  return (
    <>
      <CityInput name="origin_city_id" label="Origin" />
      <CityInput name="destination_city_id" label="Destination" />

      {values.origin_city_id && values.destination_city_id ? (
        <>
          <Suspense fallback={<Skeleton className="col-span-full h-[254px]" />}>
            <Trip />
          </Suspense>

          <FormDivider />

          <CurrencyInput
            name="amount"
            label="Amount"
            currency={values.currency}
          />
        </>
      ) : null}
    </>
  );
}

function Trip() {
  const { values } = useForm<CreatePaidDriverRateInput>();
  const { data } = useSuspenseGqlQuery(tripByCities, {
    cityAId: values.origin_city_id,
    cityBId: values.destination_city_id,
  });

  return (
    <div className="col-span-full">
      <ImoovaImage
        className="w-full"
        media={data.tripByCities.landscape_image}
        alt="trip image"
      />

      <div className="space-x-3">
        <span>{data.tripByCities.distance}km</span>
        <span className="inline-block size-2 rounded-full bg-gray-400" />
        <span>{data.tripByCities.duration}hrs</span>
      </div>
    </div>
  );
}
