"use client";

import {
  Column,
  ColumnDef,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  Table as TableType,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { PaginationFieldsFragment } from "@/gql/graphql";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { keepPreviousData } from "@tanstack/react-query";
import { Variables } from "graphql-request";
import { Button } from "@/components/ui/button";
import { Button as CatalystButton } from "@/components/catalyst/button";
import { ReactNode, useEffect, useMemo, useState } from "react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CaretSortIcon,
} from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SlidersHorizontalIcon } from "lucide-react";
import { useLocalStorage } from "react-use";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { GenericMenu } from "@/lib/Components/Menu/GenericMenu";
import { flattenActions } from "@/lib/Components/Actions/utils/flattenActions";
import { Skeleton } from "@/components/ui/skeleton";
import { Input, InputGroup } from "@/components/catalyst/input";
import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";

export type DataTableColDef<TData, TValue, TColumn extends string> = ColumnDef<
  TData,
  TValue
> & {
  id: TColumn;
  isHidden?: boolean;
};

type SortingState<TColumn> = {
  desc: boolean;
  id: TColumn;
}[];

interface DataTableProps<
  TQuery,
  TVariables extends Variables | undefined,
  TColumn extends string,
  TData,
  TValue,
> {
  columns: DataTableColDef<TData, TValue, TColumn>[];
  document: TypedDocumentNode<TQuery, TVariables>;
  getQueryVariables: ({
    pagination,
    sorting,
    search,
  }: {
    pagination: {
      pageIndex: number;
      pageSize: number;
    };
    sorting: {
      desc: boolean;
      id: TColumn;
    }[];
    search: string;
  }) => TVariables;
  accessor: (data: TQuery) => {
    data: TData[];
    paginatorInfo: PaginationFieldsFragment;
  };
  title?: string;
  rightButtons?: ReactNode | ((table: TableType<TData>) => ReactNode);
  filters?: ReactNode | ((table: TableType<TData>) => ReactNode);
  id: string;
  hiddenColumns?: TColumn[];
  initialSorting?: SortingState<TColumn>;
  searchable?: boolean;
  columnToggles?: boolean;
  getActions?: NoInfer<ActionsHook<TData>>;
}

export function DataTable<
  TQuery,
  TVariables extends Variables | undefined,
  TColumn extends string,
  TData,
  TValue,
>({
  columns,
  document,
  accessor,
  getQueryVariables,
  title,
  rightButtons,
  filters,
  id,
  hiddenColumns,
  initialSorting = [],
  searchable = true,
  columnToggles = true,
  getActions,
}: DataTableProps<TQuery, TVariables, TColumn, TData, TValue>) {
  const { isAdmin } = useTenant();

  const [sorting = [], setSorting] = useLocalStorage(
    `table-${id}-sorting`,
    initialSorting,
  );

  const columnIds = columns.map((column) => [column.id, true]);
  const initialHiddenColumns = Object.fromEntries(columnIds);
  hiddenColumns?.forEach((column) => {
    initialHiddenColumns[column] = false;
  });

  const [columnVisibility = {}, setColumnVisibility] = useLocalStorage(
    `table-${id}-columns`,
    initialHiddenColumns,
  );

  const [pageSize = 10, setPageSize] = useLocalStorage(
    `table-${id}-page-size`,
    10,
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize, //default page size
  });

  useEffect(() => {
    setPageSize(pagination.pageSize);
  }, [pagination.pageSize]);

  const [search, setSearch] = useState("");

  const {
    data: res,
    isLoading,
    isPlaceholderData,
  } = useGqlQuery(
    document,
    getQueryVariables({
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex + 1, //graphql pagination starts at 1
      },
      sorting,
      search,
    }),
    {
      placeholderData: keepPreviousData,
    },
  );

  const memoizedColumns = useMemo(() => {
    return columns.filter((c) => !c.isHidden);
  }, [isAdmin]);

  const isFetching = isLoading || isPlaceholderData;

  const { data, paginatorInfo } = res
    ? accessor(res)
    : {
        data: [],
        paginatorInfo: {
          lastPage: 1,
          total: 0,
          currentPage: 1,
          hasMorePages: false,
          count: 0,
          perPage: 10,
        } satisfies PaginationFieldsFragment,
      };

  const table = useReactTable({
    data,
    columns: memoizedColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: (updaterOrValue) => {
      const newVal = functionalUpdate(updaterOrValue, pagination);
      setPagination(newVal);
    },
    pageCount: paginatorInfo.lastPage,
    rowCount: paginatorInfo.total,
    manualSorting: true,
    enableSorting: true,
    onColumnVisibilityChange: (updaterOrValue) => {
      const newColumnVisibility = functionalUpdate(
        updaterOrValue,
        columnVisibility,
      );
      setColumnVisibility(newColumnVisibility);
    },
    onSortingChange: (sortingUpdater) => {
      const newSortVal = functionalUpdate(sortingUpdater, sorting);
      setSorting(newSortVal as any);
    },
    state: {
      pagination,
      sorting,
      columnVisibility,
      globalFilter: search,
    },
  });

  return (
    <div>
      {title ? (
        <h2 className="text-lg font-medium leading-6 text-gray-900">{title}</h2>
      ) : null}

      <div className="flex w-full flex-col gap-4 py-2 sm:flex-row sm:items-center sm:py-2">
        {searchable ? (
          <InputGroup>
            <MagnifyingGlassIcon />
            <Input
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        ) : null}
        {columnToggles ? <DataTableViewOptions table={table} /> : null}
        <div>{typeof filters === "function" ? filters(table) : filters}</div>
        <div className="hidden sm:block sm:flex-grow"></div>
        <div>
          {typeof rightButtons === "function"
            ? rightButtons(table)
            : rightButtons}
        </div>
      </div>

      <div className="isolate overflow-auto rounded-lg border border-gray-200">
        <Table>
          <TableHeader isLoading={isFetching}>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}

                {getActions ? <th /> : null}
              </TableRow>
            ))}
          </TableHeader>

          {isLoading ? (
            <tbody className="">
              {Array.from({ length: 2 }).map((i, idx) => {
                return (
                  <tr key={idx}>
                    <td colSpan={100} className="p-2">
                      <Skeleton className="h-16 w-full" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}

                    {getActions ? (
                      <TableCell
                        className="table-actions bg- sticky -right-[1px] max-w-10 bg-white px-2"
                        width={40}
                      >
                        <GenericMenu
                          button={
                            <div className="rounded-md p-1 ring-gray-200 hover:bg-gray-50 hover:ring-1 active:bg-gray-100 group-hover:shadow">
                              <EllipsisVerticalIcon className="h-4 w-4 text-gray-500" />
                            </div>
                          }
                          actionGroups={flattenActions(
                            getActions(row.original),
                          )}
                        />
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}

interface DataTablePaginationProps<TData> {
  table: TableType<TData>;
}

export function DataTablePagination<TData>({
  table,
}: DataTablePaginationProps<TData>) {
  const pagination = table.getState().pagination;
  const start = pagination.pageIndex * pagination.pageSize + 1;
  const end = Math.min(
    (pagination.pageIndex + 1) * pagination.pageSize,
    table.getRowCount(),
  );

  return (
    <div className="flex justify-between text-xs">
      <div className="text-muted-foreground flex flex-1 items-center">
        <span>
          Viewing{" "}
          <span className="font-semibold">
            {start} - {end}
          </span>{" "}
          of <span className="font-semibold">{table.getRowCount()}</span>{" "}
          results
        </span>
        <span className="ml-2 inline-flex h-1 w-1 rounded-full bg-gray-100" />
        <select
          className="rounded-md border-0 text-xs"
          value={`${table.getState().pagination.pageSize}`}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 50, 100].map((pageSize) => (
            <option key={pageSize} value={`${pageSize}`}>
              {pageSize} per page
            </option>
          ))}
        </select>
      </div>
      <div className="mt-2 flex space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className=""
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="">Previous</span>
          </Button>
          <Button
            variant="outline"
            className=""
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="">Next</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  return (
    <CatalystButton
      plain
      onClick={() => {
        column.toggleSorting();
      }}
      className="text-muted-foreground -translate-x-2"
    >
      <span>{title}</span>
      {column.getIsSorted() === "desc" ? (
        <ArrowDownIcon className="ml-2 h-4 w-4" />
      ) : column.getIsSorted() === "asc" ? (
        <ArrowUpIcon className="ml-2 h-4 w-4" />
      ) : (
        <CaretSortIcon className="ml-2 h-4 w-4" />
      )}
    </CatalystButton>
  );
}

interface DataTableViewOptionsProps<TData> {
  table: TableType<TData>;
}

export function DataTableViewOptions<TData>({
  table,
}: DataTableViewOptionsProps<TData>) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="ml-auto hidden h-9 lg:flex"
        >
          <SlidersHorizontalIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="w-[150px]">
        <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {table
          .getAllColumns()
          .filter((column) => column.getCanHide())
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
              >
                {column.id}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
