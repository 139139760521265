import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { UpdateSupplierTripInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { SupplierTripForm } from "@/app/SupplierTrips/Forms/SupplierTripForm";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { supplierTripByOfficesQuery } from "@/app/SupplierTrips/GraphQL/supplierTripRecord";
import { updateSupplierTripMutation } from "@/app/SupplierTrips/GraphQL/supplierTripMutations";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { createSupplierTripValidator } from "@/app/SupplierTrips/Validators/createSupplierTripValidator";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

type SupplierTripEditDialogProps = {
  officeAId: string;
  officeBId: string;
};

export function SupplierTripEditDialog({
  officeAId,
  officeBId,
}: SupplierTripEditDialogProps) {
  const { mutateAsync } = useGqlMutation(updateSupplierTripMutation);
  const { close } = useDialogState();

  const { data } = useSuspenseGqlQuery(supplierTripByOfficesQuery, {
    officeAId,
    officeBId,
  });

  if (!data.supplierTripByOffices) {
    throw new Error("No trip found");
  }

  const {
    data: { supplier },
  } = useSuspenseGqlQuery(supplierRecordQuery, {
    id: data.supplierTripByOffices.supplier.id,
  });

  const trip = data.supplierTripByOffices;

  return (
    <GenericDialog title="Update trip">
      <GenericForm<UpdateSupplierTripInput>
        validationSchema={toFormikValidationSchema(createSupplierTripValidator)}
        onSubmit={async (values) => {
          await mutateAsync({
            id: trip.id,
            input: values,
          });
          close();
        }}
        initialValues={{
          supplier: {
            connect: trip.supplier.id,
          },
          officeA: {
            connect: trip.officeA.id,
          },
          officeB: {
            connect: trip.officeB.id,
          },
          distance_allowed: trip.distance_allowed,
          hire_units_allowed: trip.hire_units_allowed,
          extra_hire_units_allowed: trip.extra_hire_units_allowed,
          minimum_hire_units: trip.minimum_hire_units,
          is_ferry_required: trip.is_ferry_required,
          inclusions: {
            upsert: trip.inclusions.map((inclusion) => ({
              id: inclusion.id,
              type: inclusion.type,
              value: inclusion.value,
              description: inclusion.description,
            })),
            delete: [],
          },
        }}
      >
        <SupplierTripForm supplier={supplier} />
        <DialogSubmitButton text="Update trip" />
      </GenericForm>
    </GenericDialog>
  );
}
