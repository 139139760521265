import { graphQLClient } from "@/lib/GraphQLRequest/GraphQLClient";
import * as Sentry from "@sentry/react";
import { Variables } from "graphql-request";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
  DefaultError,
  InfiniteData,
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  useSuspenseQuery,
  UseSuspenseQueryOptions,
} from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
import { QueryClient } from "@/lib/ReactQuery/QueryClient";

type UseAuthReturn = ReturnType<typeof useAuth>;

export async function query<TData, TVariables extends Variables | undefined>(
  query: TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  auth: UseAuthReturn,
): Promise<TData> {
  const { getToken } = auth;

  return Sentry.startSpan(
    { op: getQueryType(query), name: getQueryName(query) },
    async (span) => {
      const headers: Record<string, string> = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "sentry-trace": Sentry.spanToTraceHeader(span),
        Authorization: `Bearer ${await getToken({
          template: "admin",
        })}`,
      };
      const baggage = Sentry.spanToBaggageHeader(span);
      if (baggage) {
        headers["baggage"] = baggage;
      }

      try {
        return graphQLClient.request(query, variables, headers);
      } catch (error) {
        Sentry.withScope((scope) => {
          scope.setFingerprint([JSON.stringify(getQueryKey(query, variables))]);
          Sentry.captureException(error);
        });

        console.error(error);

        throw error;
      }
    },
  );
}

export const useFetchData = <TData, TVariables extends Variables | undefined>(
  document: TypedDocumentNode<TData, TVariables>,
): ((variables: TVariables) => Promise<TData>) => {
  // generate unique transactionId and set as Sentry tag
  const auth = useAuth();

  return (variables): Promise<TData> => {
    return query(document, variables, auth);
  };
};

export function useGqlQuery<TData, TVariables extends Variables | undefined>(
  query: TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  options?: Partial<UseQueryOptions>,
): UseQueryResult<TData> {
  const fetcher = useFetchData(query);
  return useQuery({
    queryKey: getQueryKey(query, variables),
    queryFn: () => fetcher(variables),
    ...options,
  }) as UseQueryResult<TData>;
}

export function useSuspenseGqlQuery<
  TData,
  TVariables extends Variables | undefined,
>(
  query: TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  options?: Partial<UseSuspenseQueryOptions<TData, DefaultError>>,
) {
  const fetcher = useFetchData(query);
  return useSuspenseQuery({
    queryKey: getQueryKey(query, variables),
    queryFn: () => fetcher(variables),
    ...options,
  });
}
export function useNullableSuspenseGqlQuery<
  TData,
  TVariables extends Variables | undefined,
>(
  query: TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  isEnabled: boolean,
  options?: Partial<UseSuspenseQueryOptions<TData | null, DefaultError, TData>>,
) {
  const fetcher = useFetchData(query);

  return useSuspenseQuery<TData | null, DefaultError, TData>({
    queryKey: getQueryKey(query, variables),
    queryFn: () => (isEnabled ? fetcher(variables) : null),
    ...options,
  });
}

export function useInfiniteGqlQuery<
  TData,
  TVariables extends Variables | undefined,
>(
  query: TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  options: Omit<
    UseInfiniteQueryOptions<
      TData,
      DefaultError,
      InfiniteData<TData, { page: number }>
    >,
    "queryFn" | "queryKey"
  >,
) {
  const fetcher = useFetchData(query);
  return useInfiniteQuery({
    queryKey: getQueryKey(query, variables, true),
    queryFn: ({ pageParam }: any) =>
      fetcher({
        ...variables,
        ...pageParam,
      }),
    placeholderData: keepPreviousData,
    ...options,
  });
}

export function getQueryKey<TData, TVariables extends Variables | undefined>(
  query: TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  isInfinite?: boolean,
): string[] {
  let queryName = getQueryName(query);
  if (isInfinite) {
    queryName += `.Infinite`;
  }

  return [queryName, variables];
}

function getQueryName(query: TypedDocumentNode<any, any>) {
  return (query.definitions[0] as any).name.value;
}

function getQueryType(query: TypedDocumentNode<any, any>) {
  const definition = query.definitions[0] as any;

  if (definition.operation === "query") {
    return "http.graphql.query";
  }

  return "http.graphql.mutation";
}

export function useGqlMutation<TData, TVariables extends Variables | undefined>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: Partial<UseMutationOptions>,
): UseMutationResult<TData, DefaultError, TVariables> {
  const fetcher = useFetchData(query);
  return useMutation<any, any, any>({
    mutationFn: (variables: TVariables) => fetcher(variables) as any,
    ...options,
  });
}

export function prefetchInfiniteQuery<
  TData,
  TVariables extends Variables | undefined,
>(
  document: TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  auth: UseAuthReturn,
): Promise<any> {
  const infiniteKey = getQueryKey(document, variables, true);

  return (
    QueryClient.getQueryData(infiniteKey) ??
    QueryClient.fetchInfiniteQuery({
      initialPageParam: {
        page: 1,
      },
      queryKey: infiniteKey,
      queryFn: () => query(document, variables, auth),
    })
  );
}

export function fetchQuery<TData, TVariables extends Variables | undefined>(
  document: TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  auth: UseAuthReturn,
): Promise<TData> {
  return QueryClient.ensureQueryData({
    queryKey: getQueryKey(document, variables),
    queryFn: () => query(document, variables, auth),
  });
}
