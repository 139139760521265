import { QuoteListQueryVariables } from "@/gql/graphql";
import { QuoteListSearchParams } from "@/app/Quotes/routes/quoteRoutes";

export function getQuoteListQueryVariables(
  searchParams: QuoteListSearchParams,
  search?: string,
): QuoteListQueryVariables {
  const baseParams = {
    first: 20,
    page: 1,
    status: searchParams.quoteStatus,
    search,
  };

  return {
    ...baseParams,
    // orderBy: search
    //   ? undefined
    //   : [
    //       {
    //         order: SortOrder.Desc,
    //         column: QueryInvoicesOrderByColumn.Id,
    //       },
    //     ],
  };
}
