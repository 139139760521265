import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";
import { useSupplierActions } from "@/app/Suppliers/Hooks/useSupplierActions";
import {
  SupplierListItem,
  supplierListQuery,
} from "@/app/Suppliers/GraphQL/supplierListQuery";
import { ModelLinkCell } from "@/lib/Components/DataTable/Cells/ModelLinkCell";
import { DeEmphasisedCell } from "@/lib/Components/DataTable/DeEmphasisedCell";
import { VariablesOf } from "@graphql-typed-document-node/core";
import {
  QuerySuppliersOrderByColumn,
  QuerySuppliersOrderByOrderByClause,
  SortOrder,
} from "@/gql/graphql";

type SupplierTableColumnId =
  | "supplier"
  | "booking_fee"
  | "charge_per_move"
  | "holding_deposit"
  | "holding_deposit_refunded_by"
  | "holding_deposit_refunded_at";

export function SupplierTable({
  id,
  queryVariables,
}: {
  id: TableId;
  queryVariables?: Partial<VariablesOf<typeof supplierListQuery>>;
}) {
  const getActions = useSupplierActions();
  const columns: DataTableColDef<
    SupplierListItem,
    any,
    SupplierTableColumnId
  >[] = [
    {
      id: "supplier",
      accessorFn: (s) => s,
      cell: ({ row }) => (
        <ModelLinkCell
          to={"/suppliers/$id"}
          params={{
            id: row.original.id,
          }}
        >
          {row.original.name}
        </ModelLinkCell>
      ),
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Supplier" />
      ),
    },
    {
      id: "charge_per_move",
      header: "Charge per move",
      accessorFn: (row) =>
        formatCurrency(row.charge_per_move_amount, row.billing_currency),
      cell: DeEmphasisedCell,
    },
    {
      id: "holding_deposit",
      header: "Holding deposit",
      accessorFn: (row) =>
        formatCurrency(row.holding_deposit_amount, row.currency),
      cell: DeEmphasisedCell,
    },
    {
      id: "holding_deposit_refunded_by",
      header: "Refunded by",
      accessorFn: (row) => row.holding_deposit_refunded_by,
      cell: DeEmphasisedCell,
    },
    {
      id: "holding_deposit_refunded_at",
      header: "Refunded at",
      accessorFn: (row) => row.holding_deposit_refunded_at,
      cell: DeEmphasisedCell,
    },
  ];

  return (
    <DataTable
      id={id}
      getActions={getActions}
      columns={columns}
      getQueryVariables={({ search, pagination, sorting }) => {
        return {
          search,
          page: pagination.pageIndex,
          first: pagination.pageSize,
          orderBy: sorting.length
            ? sorting.map((s) => {
                const order = s.desc ? SortOrder.Desc : SortOrder.Asc;

                if (s.id !== "supplier") {
                  console.warn(`${s.id} as not been mapped to a column`);
                }

                return {
                  order,
                  column: QuerySuppliersOrderByColumn.Name,
                } satisfies QuerySuppliersOrderByOrderByClause;
              })
            : undefined,
          ...queryVariables,
        };
      }}
      document={supplierListQuery}
      accessor={(res) => res.suppliers}
    />
  );
}
