import {
  SupplierTripListItem,
  supplierTripListQuery,
} from "@/app/SupplierTrips/GraphQL/supplierTripListQuery";
import {
  HireUnitType,
  QuerySupplierOfficesOrderByColumn,
  QuerySupplierTripsWhereColumn,
  RelocationInclusionType,
  SortOrder,
} from "@/gql/graphql";
import { useMemo, useState } from "react";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierTripCreateDialog } from "@/app/SupplierTrips/Components/SupplierTripCreateDialog";
import { FuelIcon, ShipIcon } from "lucide-react";
import { SupplierTripEditDialog } from "@/app/SupplierTrips/Components/SupplierTripEditDialog";
import { cn } from "@/lib/utils";
import {
  useFetchData,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { formatDistance } from "@/lib/Formatters/formatCurrency";
import { GridIconFull } from "@/assets/Icons/GridIconFull";
import { GridIconHalf } from "@/assets/Icons/GridIconHalf";
import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { Button } from "@/components/catalyst/button";
import { useSuspenseQuery } from "@tanstack/react-query";
import { supplierOfficeListQuery } from "@/app/Offices/GraphQL/supplierOfficeListQuery";

const hireUnitDisplayMap = {
  [HireUnitType.Day]: "days",
  [HireUnitType.Night]: "nights",
  [HireUnitType.TwentyFourHours]: "24hrs",
};

export function SupplierTripMatrix({ supplierId }: { supplierId: string }) {
  const { data: officeData } = useSuspenseGqlQuery(supplierOfficeListQuery, {
    page: 1,
    archived: false,
    supplier_id: supplierId,
    first: 200,
    orderBy: [
      {
        column: QuerySupplierOfficesOrderByColumn.Name,
        order: SortOrder.Asc,
      },
    ],
  });

  const supplierOffices = officeData.supplierOffices.data;

  const fetcher = useFetchData(supplierTripListQuery);
  const { data: supplierTrips } = useSuspenseQuery({
    queryFn: async () => {
      let page = 0;
      const items = [];

      while (true) {
        const res = await fetcher({
          first: 200,
          page,
          where: {
            column: QuerySupplierTripsWhereColumn.SupplierId,
            value: supplierId,
          },
        });

        items.push(...res.supplierTrips.data);
        page++;

        if (!res.supplierTrips.paginatorInfo.hasMorePages) {
          break;
        }
      }

      return items;
    },
    queryKey: [
      "AllTrips",
      {
        supplierId,
      },
    ],
  });

  const matrixData = useMemo(() => {
    return supplierOffices.map((officeA) => {
      return supplierOffices.map((officeB) => {
        return supplierTrips.find((trip) => {
          return (
            (trip.officeB.id === officeB.id &&
              trip.officeA.id === officeA.id) ||
            (trip.officeB.id === officeA.id && trip.officeA.id === officeB.id)
          );
        });
      });
    });
  }, [supplierTrips, supplierId]);

  const numCols = matrixData.at(0)?.length ?? 0;
  const numRows = matrixData.length;

  const { open: openNew } = useDialog(SupplierTripCreateDialog);
  const { open: openEdit } = useDialog(SupplierTripEditDialog);

  const [showBiDirectional, setShowBiDirectional] = useState(false);

  if (supplierTrips.length === 0) {
    return (
      <RecordScreenCard
        buttons={
          <Button
            onClick={() => {
              openNew({
                supplierId,
              });
            }}
          >
            Add trip
          </Button>
        }
      >
        No trips found
      </RecordScreenCard>
    );
  }

  return (
    <div
      className="isolate grid max-h-[80vh] w-full overflow-auto rounded-md"
      style={{
        gridTemplateColumns: `8rem repeat(${numCols}, minmax(5rem, 1fr))`,
        gridTemplateRows: `3rem repeat(${numRows}, 3rem)`,
      }}
    >
      <div
        className={
          "sticky left-0 top-0 z-50 flex select-none items-center justify-center bg-gray-100 ring-1 ring-gray-200"
        }
      >
        <IconButton
          onClick={() => setShowBiDirectional(!showBiDirectional)}
          tooltip={"Toggle bi-direction view"}
          Icon={showBiDirectional ? GridIconHalf : GridIconFull}
        />
      </div>

      {/* Column headings */}
      {supplierOffices.map((office, index) => (
        <div
          key={`col-${index}`}
          className="sticky top-0 z-30 flex bg-gray-100 p-2 text-xs ring-1 ring-gray-200"
        >
          {office.name}
        </div>
      ))}

      {matrixData.map((tripRow, rowIndex) => {
        const count = tripRow.filter((trip) => trip).length;
        return (
          <>
            {/* Row headings */}
            <div className="sticky left-0 z-20 flex gap-1 bg-gray-100 p-2 text-xs ring-1 ring-gray-200">
              {supplierOffices[rowIndex].name}
              {count > 0 && !showBiDirectional ? (
                <span className="text-blue-400">({count})</span>
              ) : null}
            </div>
            {tripRow.map((trip, colIndex) => (
              <SupplierTripCell
                key={`cell-${rowIndex}-${colIndex}`}
                trip={trip}
                isHidden={
                  showBiDirectional
                    ? rowIndex >= colIndex
                    : rowIndex === colIndex
                }
                onClick={() => {
                  if (trip) {
                    openEdit({
                      officeAId: trip.officeA.id,
                      officeBId: trip.officeB.id,
                    });
                  } else {
                    openNew({
                      supplierId,
                      initialValues: {
                        officeA: {
                          connect: supplierOffices[rowIndex].id,
                        },
                        officeB: {
                          connect: supplierOffices[colIndex].id,
                        },
                      },
                    });
                  }
                }}
              />
            ))}
          </>
        );
      })}
    </div>
  );
}

function SupplierTripCell({
  trip,
  isHidden,
  onClick,
}: {
  trip: SupplierTripListItem | undefined;
  isHidden: boolean;
  onClick: () => void;
}) {
  if (isHidden) {
    return (
      <div className="flex items-center justify-center">
        <div className="flex h-full w-full items-center justify-center bg-gray-100"></div>
      </div>
    );
  }

  if (!trip) {
    return (
      <button
        className={cn(
          `group flex h-full w-full items-center justify-center text-gray-500 ring-1 ring-gray-200 transition duration-300 ease-in-out hover:bg-blue-50 focus:outline-none disabled:cursor-not-allowed disabled:text-gray-500 disabled:opacity-50 disabled:shadow-none disabled:ring-0`,
        )}
        onClick={onClick}
      >
        <div className="flex items-center text-xs opacity-0 group-hover:opacity-100">
          Add
        </div>
      </button>
    );
  }

  return (
    <div className="ring-1 ring-gray-200">
      <button
        className={cn(
          "relative flex h-full w-full cursor-pointer flex-col justify-center text-sm transition-colors" +
            " hover:bg-blue-50",
          "transition duration-300 ease-in-out focus:outline-none focus:ring focus:ring-inset disabled:cursor-not-allowed disabled:text-gray-500 disabled:opacity-50 disabled:shadow-none disabled:ring-0",
        )}
        onClick={onClick}
      >
        {trip?.inclusions.find(
          (inclusion) => inclusion.type === RelocationInclusionType.Ferry,
        ) && (
          <div className="absolute right-2 top-1 flex items-center justify-center">
            <ShipIcon className="size-4 text-yellow-500" />
          </div>
        )}
        {trip?.inclusions.find(
          (inclusion) => inclusion.type === RelocationInclusionType.Fuel,
        ) && (
          <div className="absolute bottom-1 right-2 flex items-center justify-center">
            <FuelIcon className="size-4 text-gray-400" />
          </div>
        )}
        <div className="flex flex-col px-2">
          <span>
            <span className="text-gray-800">{trip?.hire_units_allowed}</span>{" "}
            <span className="text-xs">
              {hireUnitDisplayMap[trip.supplier.hire_unit_type]}
            </span>
          </span>
          <span className="text-xs text-gray-500">
            {formatDistance(trip.distance_allowed, trip.supplier.measurement)}
          </span>
        </div>
      </button>
    </div>
  );
}
