import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type ContactListItem = ResultOf<
  typeof contactListQuery
>["contacts"]["data"][number];

export const contactListQuery = graphql(`
  query ContactList(
    $page: Int
    $first: Int!
    $id: ID
    $search: String
    $type: [ContactType!]
    $objectId: ID
    $objectType: ModelType
    $managedById: ID
    $where: QueryContactsWhereWhereConditions
    $orderBy: [QueryContactsOrderByOrderByClause!]
  ) {
    contacts(
      page: $page
      first: $first
      search: $search
      type: $type
      object_id: $objectId
      object_type: $objectType
      managed_by_id: $managedById
      where: $where
      orderBy: $orderBy
      id: $id
    ) {
      data {
        id
        type
        name
        legal_name
        purchase_order_number
        company_number
        billing_cycle_day_of_month
        emails {
          id
          email
          is_primary
          is_billing
          is_invoice_reminder
        }
        phones {
          id
          phone
          is_billing
          is_primary
        }
        billingEmail {
          id
          email
        }
        billingAddress {
          ...AddressFields
        }
        profilePicture {
          ...MediaFields
        }
        created_at
        updated_at
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
