import { FileUploader } from "@/lib/FileUpload/FileUploader";
import { MediaCollection, MediaFieldsFragment, ModelType } from "@/gql/graphql";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  createMediaMutation,
  deleteMediaMutation,
  mediaSetNewOrderMutation,
} from "@/app/Media/GraphQL/mediaMutations";
import { VehicleRecord } from "@/app/Vehicles/GraphQL/vehicleRecordQuery";
import { useTranslations } from "use-intl";
import { SortableList } from "@/lib/Components/Sortable/SortableList";
import {
  SortableItem,
  SortableItemDragHandle,
} from "@/lib/Components/Sortable/SortableItem";
import { Button } from "@/lib/Components/Button/Button";
import { ImoovaImage } from "@/app/Media/Components/Image";
import { useQueryClient } from "@tanstack/react-query";

export function SortableVehicleImages({ record }: { record: VehicleRecord }) {
  const t = useTranslations("vehicle.type");
  const [items, setItems] = useState(record.images);
  const queryClient = useQueryClient();

  const { mutateAsync: createMedia } = useGqlMutation(createMediaMutation, {
    onSuccess: () => {
      return queryClient.invalidateQueries({
        predicate: (query) => {
          return (query.queryKey.at(0) as string)?.includes("Vehicle");
        },
      });
    },
  });
  const { mutateAsync: setNewOrder } = useGqlMutation(
    mediaSetNewOrderMutation,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey.at(0) as string)?.includes("Vehicle");
          },
        });
      },
    },
  );

  const isOrderDifferent =
    items.map((item) => item.id).join(",") !==
    record.images.map((item) => item.id).join(",");

  //Watch the size of the images array and reset the items if it changes
  useEffect(() => {
    setItems(record.images);
  }, [record.images.length, record.id]);

  return (
    <div>
      {record.images.length > 0 ? (
        <SortableList
          items={items}
          onChange={setItems}
          renderItem={(item) => {
            return (
              <SortableItem id={item.id}>
                <MediaPreview media={item} />
                <SortableItemDragHandle />
              </SortableItem>
            );
          }}
        />
      ) : (
        <div>
          <img src={record.heroImage} alt="default image" />
          <div className="mt-2 text-xs text-gray-500">
            This is the default image for a {t(record.type)}.
          </div>
        </div>
      )}

      {isOrderDifferent ? (
        <div className="mt-6">
          <Button
            onClick={async () => {
              await setNewOrder({
                ids: items.map((item) => item.id),
              });
            }}
          >
            Save changes
          </Button>
        </div>
      ) : null}

      <div className="pt-10">
        <FileUploader
          onUpload={(uploads) => {
            uploads.map((upload) => {
              return createMedia({
                input: {
                  file_name: upload.file.name,
                  objectId: record.id,
                  objectType: ModelType.Vehicle,
                  collectionName: MediaCollection.Images,
                  key: upload.temporary_url,
                },
              });
            });
          }}
        />
      </div>
    </div>
  );
}

function MediaPreview({ media }: { media: MediaFieldsFragment }) {
  const queryClient = useQueryClient();
  const { mutateAsync: deleteMedia, isPending } = useGqlMutation(
    deleteMediaMutation,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey.at(0) as string)?.includes("Vehicle");
          },
        });
      },
    },
  );

  return (
    <div className="relative">
      <ImoovaImage media={media} alt={media.name} />
      <div className="absolute left-1 top-1">
        <IconButton
          isLoading={isPending}
          Icon={TrashIcon}
          intent="danger"
          tooltip="Delete"
          onClick={async () => {
            await deleteMedia({
              id: media.id,
            });
          }}
        />
      </div>
    </div>
  );
}
