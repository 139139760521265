import { integrationLinksList } from "@/app/Settings/Settings/GraphQL/integrationLinksQueries";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { CellContext } from "@tanstack/react-table";
import { VehicleListItem } from "@/app/Vehicles/GraphQL/vehicleListQuery";
import { ModelType } from "@/gql/graphql";
import { useIntegrationRecord } from "@/app/Settings/Settings/Hooks/useIntegrationRecord";
import { useRcmVehicleCodesList } from "@/app/Settings/Integrations/Rcm/Hooks/useRcmCodes";
import { z } from "zod";
import { RcmCodeComboBox } from "@/app/Settings/Integrations/Rcm/Components/RcmCodeComboBox";

const externalIdValidator = z.object({ label: z.string(), value: z.string() });

export function RcmVehicleCodeCell({ row }: CellContext<VehicleListItem, any>) {
  const integration = useIntegrationRecord();

  const integrationLinks = useGqlQuery(integrationLinksList, {
    integration_id: integration.id,
  });

  const integrationLink = integrationLinks.data?.integrationLinks.data.find(
    (item) => {
      return (
        item.object_type === ModelType.Vehicle &&
        item.object_id === row.original.id
      );
    },
  );

  const { data, isLoading } = useRcmVehicleCodesList();

  const externalIds = data
    ? Array.from(
        new Set(
          data.results.map((vehicle) => {
            return JSON.stringify({
              label: vehicle.categorytype,
              value: vehicle.vehiclecategorytypeid.toString(),
            });
          }),
        ),
      ).map((vehicle) => {
        return externalIdValidator.parse(JSON.parse(vehicle));
      })
    : [];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <RcmCodeComboBox
      rcmIntegration={integration}
      externalIds={externalIds}
      rowItem={row.original}
      integrationLink={integrationLink}
      type={ModelType.Vehicle}
    />
  );
}
