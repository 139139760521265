import { FileUploader } from "@/lib/FileUpload/FileUploader";

export type Upload = { file: File; temporary_url: string };
type FileUploaderProps = {
  onDrop?: (files: File[]) => void;
  onUpload: (uploads: Upload[]) => void;
};
export function PdfUploader({ onUpload, onDrop }: FileUploaderProps) {
  return (
    <FileUploader
      onUpload={onUpload}
      accept={{ "application/pdf": [".pdf"] }}
    />
  );
}
