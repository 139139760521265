import { graphql } from "@/gql";

export const quoteStatusCountQuery = graphql(`
  query QuoteStatusCountQuery {
    quoteStatusCount {
      status
      count
    }
  }
`);
