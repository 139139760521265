import { ReactNode, Suspense } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from "@/components/catalyst/description-list";
import { formatDate } from "@/lib/Formatters/formatDate";
import { inclusionIconMap } from "@/app/Relocations/Utils/inclusionIconMap";
import {
  formatCurrency,
  formatDistance,
} from "@/lib/Formatters/formatCurrency";
import { useTranslations } from "use-intl";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { relocationRecordQuery } from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";

export function RelocationDetailsDialog({
  children,
  relocationId,
}: {
  children: ReactNode;
  relocationId: string;
}) {
  return (
    <Dialog>
      <DialogTrigger className="text-left transition-colors hover:bg-gray-100">
        {children}
      </DialogTrigger>
      <DialogContent>
        <Suspense
          fallback={
            <div className="h-full w-full items-center justify-center">
              <Spinner />
            </div>
          }
        >
          <RelocationDialogContent relocationId={relocationId} />
        </Suspense>
      </DialogContent>
    </Dialog>
  );
}

export function RelocationDialogContent({
  relocationId,
}: {
  relocationId: string;
}) {
  const t = useTranslations("relocation");

  const { data } = useSuspenseGqlQuery(relocationRecordQuery, {
    id: relocationId,
  });

  const relocation = data.relocation;

  return (
    <>
      <DialogHeader>
        <DialogTitle>{`${relocation.departureOffice.name} to ${relocation.deliveryOffice.name} (${relocation.id})`}</DialogTitle>
        <DialogDescription>
          {formatDate(relocation.available_from_date)} -{" "}
          {formatDate(relocation.available_to_date)}
        </DialogDescription>
      </DialogHeader>
      <DescriptionList>
        <DescriptionTerm>Vehicle</DescriptionTerm>
        <DescriptionDetails>{relocation.vehicle.name}</DescriptionDetails>

        <DescriptionTerm>Duration</DescriptionTerm>
        <DescriptionDetails>
          {relocation.hire_units_allowed} +{" "}
          {relocation.extra_hire_units_allowed}
          {" x "}
          {t(`hire_unit_type.${relocation.hire_unit_type}`)}s
        </DescriptionDetails>

        <DescriptionTerm>Distance</DescriptionTerm>
        <DescriptionDetails>
          {formatDistance(relocation.distance_allowed, relocation.measurement)}
        </DescriptionDetails>

        <DescriptionTerm>Inclusions</DescriptionTerm>
        <DescriptionDetails>
          <ul>
            {relocation.inclusions.map(({ id, type, value, description }) => {
              const Icon = inclusionIconMap[type];

              return (
                <li
                  key={id}
                  className="flex items-center space-x-2 text-xs text-gray-500"
                >
                  <Icon className="h-4 w-4 flex-shrink-0 text-yellow-400" />
                  {value ? (
                    <span>{formatCurrency(value, relocation.currency)}</span>
                  ) : null}
                  <div
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: description ?? "--",
                    }}
                  />
                </li>
              );
            })}

            {relocation.inclusions.length === 0 ? "--" : null}
          </ul>
        </DescriptionDetails>

        <DescriptionTerm>Insurance</DescriptionTerm>
        <DescriptionDetails>
          {relocation.supplierInsuranceOption?.name ?? "--"}
        </DescriptionDetails>

        <DescriptionTerm>Bond</DescriptionTerm>
        <DescriptionDetails>
          {formatCurrency(
            relocation.supplierInsuranceOption?.bond_amount,
            relocation.currency,
          )}
        </DescriptionDetails>

        <DescriptionTerm>Standard liability</DescriptionTerm>
        <DescriptionDetails>
          {formatCurrency(
            relocation.supplierInsuranceOption?.standard_liability_amount,
            relocation.currency,
          )}
        </DescriptionDetails>

        {relocation.supplierInsuranceOption?.liability_reduction_options ? (
          <>
            <DescriptionTerm>Reduction options</DescriptionTerm>
            <DescriptionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    relocation.supplierInsuranceOption
                      .liability_reduction_options,
                }}
              />
            </DescriptionDetails>
          </>
        ) : null}
      </DescriptionList>
    </>
  );
}
