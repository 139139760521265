import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { Navbar, NavbarItemLink } from "@/components/catalyst/navbar";
import { Outlet } from "@tanstack/react-router";

export function PaidDriverLayout() {
  return (
    <FullPageScreen className="space-y-6">
      <Navbar>
        <NavbarItemLink to="/paid-drivers/drivers">Drivers</NavbarItemLink>
        <NavbarItemLink to="/paid-drivers/quotes">Quotes</NavbarItemLink>
        <NavbarItemLink to="/paid-drivers/rates">Rates</NavbarItemLink>
      </Navbar>

      <Outlet />
    </FullPageScreen>
  );
}
