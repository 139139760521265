import { OfficeTable } from "@/app/Offices/Components/OfficeTable";
import { SettingCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { OfficeCreateDialog } from "@/app/Offices/Components/OfficeCreateDialog";
import { Button } from "@/lib/Components/Button/Button";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { SupplierTripMatrix } from "@/app/SupplierTrips/Components/SupplierTripMatrix";
import { supplierOfficeListQuery } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { QuerySupplierOfficesOrderByColumn, SortOrder } from "@/gql/graphql";
import { FullscreenIcon } from "lucide-react";
import { SupplierTripMatrixDialog } from "@/app/SupplierTrips/Components/SupplierTripMatrixDialog";

export function SettingsOfficeScreen() {
  const { supplierId } = useGlobalSupplier();
  const { open } = useDialog(OfficeCreateDialog);
  const { open: openFullscreen } = useDialog(SupplierTripMatrixDialog);

  if (!supplierId) {
    return null;
  }

  const { data: officeData } = useSuspenseGqlQuery(supplierOfficeListQuery, {
    page: 1,
    archived: false,
    supplier_id: supplierId,
    first: 200,
    orderBy: [
      {
        column: QuerySupplierOfficesOrderByColumn.Name,
        order: SortOrder.Asc,
      },
    ],
  });

  return (
    <div className="space-y-12">
      <SettingCard
        title="Offices"
        description="A list of offices you would like to send your vehicles between"
      >
        <OfficeTable
          id="settings-offices"
          rightButtons={
            <Button
              onClick={() => {
                open({
                  initialValues: {
                    supplier: {
                      connect: supplierId,
                    },
                  },
                });
              }}
            >
              Add office
            </Button>
          }
        />
      </SettingCard>

      {officeData.supplierOffices.data.length > 1 ? (
        <SettingCard
          title="Trips"
          description="A list of trips you would like to send your vehicles between"
          buttons={
            <Button
              LeadingIcon={FullscreenIcon}
              className="max-w-28"
              onClick={() => {
                openFullscreen({
                  supplierId: supplierId,
                });
              }}
            >
              Expand
            </Button>
          }
        >
          <div className="flex h-full flex-col items-end gap-2">
            <SupplierTripMatrix supplierId={supplierId} />
          </div>
        </SettingCard>
      ) : null}
    </div>
  );
}
